import { useTheme } from "@mui/system"
import React from "react"
import ReactDom from "react-dom"

export default function Modal({ open, children, onClose, modalStyles = {} }) {
  const theme = useTheme()
  const styles = {
    modal: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: theme.palette.background.main,
      zIndex: 1500,
      borderRadius: "12px",
      overflow: "hidden",
      maxHeight: "100%",
      ...modalStyles
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, .7)",
      zIndex: 1300
    }
  }

  if (!open) return null

  return ReactDom.createPortal(
    <>
      <div style={styles.overlay} onClick={onClose} />
      <div style={styles.modal}>{children}</div>
    </>,
    document.getElementById("portal")
  )
}
