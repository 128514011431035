import { createAction } from "@reduxjs/toolkit"

export const raffleTicketAction = createAction("raffleTicketAction")
export const WineMonthVideoThumbnailAction = createAction("WineMonthVideoThumbnailAction")
export const uploadWineOfTheMonthAction = createAction("uploadWineOfTheMonthAction")
export const getWineMonthAction = createAction("getWineMonthAction")
export const deleteWineMonthAction = createAction("deleteWineMonthAction")
export const getSingleWineMonthDataAction = createAction("getSingleWineMonthDataAction")
export const editWineOfTheMonthItemAction = createAction("editWineOfTheMonthItemAction")

export const editRaffleContestAction = createAction("editRaffleContestAction")

export const getRaffleListDataAction = createAction("getRaffleListDataAction")

export const deleteRaffleContestAction = createAction("deleteRaffleContestAction")
export const getRaffleSingleDataAction = createAction("getRaffleSingleDataAction")

export const uploadPDFThumbnailAction = createAction("uploadPDFThumbnailAction")

export const getMenuPeriodAction = createAction("getMenuPeriodAction")

export const addCommentCalenderAction = createAction("addCommentCalenderAction")
export const EditCommentCalenderAction = createAction("EditCommentCalenderAction")
export const EditMenuCategoryAction = createAction("EditMenuCategoryAction")
export const AddMenuCategoryAction = createAction("AddMenuCategoryAction")
export const DeleteMenuCategoryAction = createAction("DeleteMenuCategoryAction")
export const uploadMenuItemAction = createAction("uploadMenuItemAction")
export const addMenuItemsActions = createAction("addMenuItemsActions")
export const deleteMenuItemAction = createAction("deleteMenuItemAction")
export const getFoodItemsAction = createAction("getFoodItemsAction")
export const updateFoodItemsAction = createAction("updateFoodItemsAction")
export const uploadTastingNotesImagesAction = createAction("uploadTastingNotesImagesAction")
export const getLeaderboardDataAction = createAction("getLeaderboardDataAction")
export const getSingleUserDataGraphAction = createAction("getSingleUserDataGraphAction")
export const revertAllMarketing = createAction("revertAllMarketing")
export const getBarMenuPeriodAction = createAction("getBarMenuPeriodAction")
export const getWineMenuPeriodAction = createAction("getWineMenuPeriodAction")
export const addWineMenuItemsAction = createAction("addWineMenuItemsAction")
export const getWineItemsAction = createAction("getWineItemsAction")
export const updateWineItemsAction = createAction("updateWineItemsAction")
export const deleteWineItemAction = createAction("deleteWineItemAction")
export const addBarMenuItemsAction = createAction("addBarMenuItemsAction")
export const getBarItemsAction = createAction("getBarItemsAction")
export const updateBarItemsAction = createAction("updateBarItemsAction")
export const deleteBarItemAction = createAction("deleteBarItemAction")
export const AddBarMenuCategoryAction = createAction("AddBarMenuCategoryAction")
export const EditBarMenuCategoryAction = createAction("EditBarMenuCategoryAction")
export const DeleteBarMenuCategoryAction = createAction("DeleteBarMenuCategoryAction")
export const unArchiveItemsAction = createAction("unArchiveItemsAction")
export const unArchiveBarItemsAction = createAction("unArchiveBarItemsAction")
export const importSpiritItemAction = createAction("importSpiritItemAction")
export const getSpiritCMSAction = createAction("getSpiritCMSAction")
export const getArchivedSpiritCMSAction = createAction("getArchivedSpiritCMSAction")
export const unArchiveSpiritsItemsAction = createAction("unArchiveSpiritsItemsAction")
export const archiveSpiritItemsAction = createAction("archiveSpiritItemsAction")
export const unarchiveWineItemsAction = createAction("unarchiveWineItemsAction")
export const addarchiveSpiritItemsAction = createAction("addarchiveSpiritItemsAction")
export const deleteSpiritMenuItemAction = createAction("deleteSpiritMenuItemAction")
export const getSpiritItemsAction = createAction("getSpiritItemsAction")
export const updateSpiritItemsAction = createAction("updateSpiritItemsAction")
export const getNewSpiritCountAction = createAction("getNewSpiritCountAction")
export const getGamificationLeaderboardDataAction = createAction(
  "getGamificationLeaderboardDataAction"
)
export const getMenuSubCategoryAction = createAction("getMenuSubCategoryAction")
export const uploadMenuBulkAction = createAction("uploadMenuBulkAction")
export const AddWineMenuCategoryAction = createAction("AddWineMenuCategoryAction")
export const editWineMenuCategoryAction = createAction("editWineMenuCategoryAction")
export const deleteWineMenuCategoryAction = createAction("deleteWineMenuCategoryAction")
export const unArchiveWineItemsAction = createAction("unArchiveWineItems")
