import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import classes from "../dmvmenu.module.scss"
import { Grid, Typography } from "@mui/material"
import { useHistory } from "react-router-dom"
import { useTheme } from "@mui/system"
import { marketingActions } from "redux/marketing/slice/user"
import CategoryModal from "./components/CategoryModal"
import AddMenuItems from "../AddDMVMenuItems"
import DMVNavBar from "components/DMVNavBar"
import {
  getBeverageAction,
  getDMVWineRegionListAction,
  getSpiritAction
} from "redux/DMV/actions/user"
import DMVMenuListContainer from "components/DMVMenuListContainer"
import SpiritsSubMenuBar from "components/SpiritsSubMenuBar"
import DMVSpiritMenuListContainer from "components/DMVSpiritMenuListContainer"
import SpiritCategoryModal from "./components/SpiritCategoryModal"
import AddSummary from "../AddDMVMenuItems/AddSummary"
import DMV_SpiritSummary from "components/DMVSpiritSummary"
import { DMVActions } from "redux/DMV/slice/user"

const buttonText = {
  CATEGORY: "+ Add Category"
}

const menuText = {
  WINE_REGION: "wine_region",
  BEVERAGE: "beverage",
  SPIRITS: "spirits"
}

function DMVEditMenu() {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const dispatch = useDispatch()
  const theme = useTheme()
  const history = useHistory()
  const [categoryModal, setCategoryModal] = useState(false)
  const [isEdit] = useState(true)
  const [currentCategoryData, setCurrentCategoryData] = useState({})
  const [isEditModal, setIsEditModal] = useState(false)
  const [isEditModalItem, setIsEditModalItem] = useState(false)
  const [menuItemsModal, setMenuItemsModal] = useState(false)
  const { linkCategory } = useSelector((state) => state?.marketing?.menu || {})
  const { dmvMenuLink, wineRegionData, beverageData, menuCategory, spiritData, SpiritSubMenu } =
    useSelector((state) => state.dmv)
  const [openModal, setOpenModal] = useState(false)
  const [catorogyList, setCatorogyList] = useState([])

  const handleRouting = () => {
    history.push("/dmv/dashboard/explore-menu")
  }

  function handleAddCategory(btnValue) {
    if (btnValue?.trim() === buttonText.CATEGORY) {
      setCategoryModal(true)
      setIsEditModal(false)
      setCurrentCategoryData(false)
      setIsEditModalItem(false)
      dispatch(marketingActions.setFoodItemsDetail({}))
    } else if (btnValue?.trim() === buttonText.ITEM) {
      setMenuItemsModal(true)
    }
  }

  function handleCloseMenuItems() {
    setMenuItemsModal(false)
    setIsEditModalItem(false)
    dispatch(marketingActions.setFoodItemsDetail({}))
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Menu"
    return () => dispatch(marketingActions.setFoodItemsDetail({}))
  }, [])

  useEffect(() => {
    if (dmvMenuLink === menuText.WINE_REGION) {
      dispatch(getDMVWineRegionListAction())
    }
    if (dmvMenuLink === menuText.BEVERAGE) {
      dispatch(getBeverageAction())
    }
    if (dmvMenuLink === menuText.SPIRITS) {
      dispatch(getSpiritAction())
    }
  }, [dmvMenuLink])

  const handleModal = () => {
    setOpenModal(!openModal)
    dispatch(DMVActions.setSpiritSummary({}))
  }

  useEffect(() => {
    let menuNav = []
    if (SpiritSubMenu?.length > 0) {
      menuNav = SpiritSubMenu?.map((item) => ({
        label: item?.name,
        value: item?.id
      }))
    }
    // menuNav.unshift({
    //   label: "Category",
    //   value: "0"
    // })
    setCatorogyList(menuNav)
  }, [SpiritSubMenu])

  return (
    <Fragment>
      <AddMenuItems
        isEdit={isEditModalItem}
        open={menuItemsModal}
        onClose={handleCloseMenuItems}></AddMenuItems>
      <AddSummary
        isEdit={false}
        open={openModal}
        onClose={handleModal}
        title="Add Description"
        categoryList={catorogyList}
      />
      {dmvMenuLink === menuText.SPIRITS ? (
        <SpiritCategoryModal
          data={currentCategoryData}
          isEdit={isEditModal}
          open={categoryModal}
          setMenuItemsModal={setMenuItemsModal}
          onClose={setCategoryModal}
        />
      ) : (
        <CategoryModal
          data={currentCategoryData}
          isEdit={isEditModal}
          open={categoryModal}
          setMenuItemsModal={setMenuItemsModal}
          onClose={setCategoryModal}></CategoryModal>
      )}

      <Container fluid className="p-0">
        <Row>
          <Col lg="12">
            <Header title={`Menu`} breadCrumbs={breadCrumbs}>
              {dmvMenuLink === menuText.SPIRITS && (
                <div style={{ marginRight: "20px" }}>
                  <AppButton onClick={handleModal}>Add Spirit Summary</AppButton>
                </div>
              )}
              <div className="d-flex align-items-center">
                <AppButton
                  styles={{
                    backgroundColor: theme.palette.background.main,
                    padding: "13px 30px"
                  }}
                  variant="filled"
                  onClick={handleRouting}>
                  Done
                </AppButton>
              </div>
            </Header>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg="12">
            <DMVNavBar isEdit={isEdit} activeTab={dmvMenuLink}></DMVNavBar>
          </Col>
        </Row>
        {dmvMenuLink === menuText.SPIRITS && (
          <Row className="mt-4">
            <Col lg={12}>
              <SpiritsSubMenuBar activeTab={menuCategory?.name} isEdit={true} />
            </Col>
          </Row>
        )}
      </Container>
      <Container fluid className="p-0 mt-4">
        <Row className="m-0 mt-2 mb-4 pb-2" style={{ borderBottom: "1px solid gray" }}>
          <Col lg="12" className="p-0 d-flex justify-content-between align-items-center">
            <Typography variant="h2">
              {linkCategory?.item === menuText.DINNER ||
              linkCategory?.item === menuText.LUNCH ||
              linkCategory?.item === menuText.BRUNCH
                ? linkCategory?.category
                : ""}
            </Typography>
            <AppButton
              onClick={(e) => handleAddCategory(e.target.innerText)}
              styles={{
                backgroundColor: theme.palette.background.main,
                padding: "10px 20px"
              }}>
              {buttonText.CATEGORY}
            </AppButton>
          </Col>
        </Row>

        {dmvMenuLink === menuText.WINE_REGION && (
          <div className={classes.menuContainer}>
            {wineRegionData?.length ? (
              wineRegionData?.map((menuItem, idx) => {
                return (
                  <DMVMenuListContainer
                    setMenuItemsModal={setMenuItemsModal}
                    setCurrentCategoryData={setCurrentCategoryData}
                    setIsEdit={setIsEditModal}
                    setIsEditModalItem={setIsEditModalItem}
                    setCategoryModal={setCategoryModal}
                    isEdit={isEdit}
                    data={menuItem}
                    key={idx}></DMVMenuListContainer>
                )
              })
            ) : (
              <Typography>No Data Found</Typography>
            )}
          </div>
        )}
        {dmvMenuLink === menuText.BEVERAGE && (
          <div className={classes.menuContainer}>
            {beverageData?.length ? (
              beverageData?.map((menuItem, idx) => {
                return (
                  <DMVMenuListContainer
                    setMenuItemsModal={setMenuItemsModal}
                    setCurrentCategoryData={setCurrentCategoryData}
                    setIsEdit={setIsEditModal}
                    setIsEditModalItem={setIsEditModalItem}
                    setCategoryModal={setCategoryModal}
                    isEdit={isEdit}
                    data={menuItem}
                    key={idx}></DMVMenuListContainer>
                )
              })
            ) : (
              <Typography>No Data Found</Typography>
            )}
          </div>
        )}
        {dmvMenuLink === menuText.SPIRITS && (
          <>
            <Grid item sx={{ maxWidth: "48%" }}>
              {SpiritSubMenu?.map(
                (foodList, i) =>
                  foodList?.id === menuCategory?.name &&
                  (foodList?.description ||
                    foodList?.image_url ||
                    foodList?.unique_facts?.length > 0) && (
                    <DMV_SpiritSummary key={i} isEdit={false} foodList={foodList} />
                  )
              )}
            </Grid>
            <div className={classes.menuContainer}>
              {spiritData?.length ? (
                spiritData?.map((menuItem, idx) => {
                  return (
                    <DMVSpiritMenuListContainer
                      setMenuItemsModal={setMenuItemsModal}
                      setCurrentCategoryData={setCurrentCategoryData}
                      setIsEdit={setIsEditModal}
                      setIsEditModalItem={setIsEditModalItem}
                      setCategoryModal={setCategoryModal}
                      isEdit={isEdit}
                      data={menuItem}
                      key={idx}></DMVSpiritMenuListContainer>
                  )
                })
              ) : (
                <Typography>No Data Found</Typography>
              )}
            </div>
          </>
        )}
      </Container>
    </Fragment>
  )
}

export default DMVEditMenu
