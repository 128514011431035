import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import NotificationListTable from "./notificationListTable"
import { Grid } from "@mui/material"
import { Formik } from "formik"
import NotificationForm from "./CreateNotificationForm"
import { validationSchemaNotification } from "helpers/validationSchema"
import { useDispatch, useSelector } from "react-redux"
import {
  EditNotificationAction,
  addNotificationAction,
  getNotificationListAction,
  getUserRoleAction
} from "redux/superAdmin/actions"
import moment from "moment"
import { superAdminAction } from "redux/superAdmin/slice/user"

const months = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12"
}

const SuperAdminNotification = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [openModal, setopenModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const dispatch = useDispatch()
  const zone_name = moment.tz.guess()
  const { notificationList, notificationData, notificationImageId } = useSelector(
    (store) => store?.superAdmin?.notifications || []
  )
  const { orderBy } = useSelector((store) => store?.app?.filters || "")

  function initDateConvert(date, time) {
    const initDate = moment(date).format("ddd MMM DD YYYY") + " " + time
    const temp = new Date(initDate)
    const convertedUTCDate = new Date(
      Date.UTC(
        temp.getFullYear(),
        temp.getMonth(),
        temp.getDate(),
        temp.getHours(),
        temp.getMinutes(),
        temp.getSeconds()
      )
    )
    return moment(convertedUTCDate).local().toDate()
  }

  const { title, image_url, scheduled_date, scheduled_time, employee_class, content } =
    notificationData || {}

  let raffleStartTime = ""

  if (scheduled_date && scheduled_time) {
    const raffleTimeStamp = initDateConvert(scheduled_date, scheduled_time)
    raffleStartTime = moment(raffleTimeStamp).format("HH:mm:ss")
  }

  const initialValuesEdit = {
    notificationName: title || "",
    uploadThumbnail: image_url
      ? {
          id: "",
          url: image_url ?? ""
        }
      : "",
    scheduleNotificationDate: scheduled_date || "",
    scheduleNotificationTime: raffleStartTime || "",
    role: employee_class || ["All"],
    notificationContent: content || ""
  }

  const initialValues = {
    notificationName: "",
    uploadThumbnail: "",
    scheduleNotificationDate: "",
    scheduleNotificationTime: "",
    role: ["All"],
    notificationContent: ""
  }

  useEffect(() => {
    dispatch(superAdminAction?.setNotificationImageId(""))
    dispatch(superAdminAction?.setNotificationData({}))
  }, [])

  function handleAddNotification() {
    setIsEdit(false)
    setopenModal(true)
  }

  function handleEditNotification() {
    setIsEdit(true)
    setopenModal(true)
  }

  function onClose() {
    setIsEdit(false)
    setopenModal(false)
    dispatch(superAdminAction.setNotificationID(null))
    dispatch(superAdminAction?.setNotificationImageId(""))
    dispatch(superAdminAction?.setNotificationData({}))
  }

  function convertDate(date, time) {
    const forDate = moment(new Date(date)).format("ddd MMM DD YYYY") + " " + time
    const newDate = new Date(forDate)
    return newDate.toUTCString()
  }
  function payloadDate(dateArray = []) {
    return `${dateArray[3]}-${months[dateArray[2]]}-${dateArray[1]}`
  }

  function handleSubmit(e) {
    let EmployeeClassList = e.role
    const valueLength = EmployeeClassList.length
    let updatedOptions

    if (EmployeeClassList?.[valueLength - 1] === "All") {
      updatedOptions = EmployeeClassList.filter((value) => value === "All")
    } else if (EmployeeClassList?.[0] === "All" && valueLength > 1) {
      updatedOptions = EmployeeClassList.filter((value) => value !== "All")
    } else {
      updatedOptions = EmployeeClassList.filter((value) => value !== "All")
    }
    let transformedList = updatedOptions.map((value) => [value])

    const NotificationTimeStamp = convertDate(
      e.scheduleNotificationDate,
      e.scheduleNotificationTime
    )
    const NotificationTimeSplit = NotificationTimeStamp.split(" ")
    const payloadStartDate = payloadDate(NotificationTimeSplit)

    const payload = {
      title: e.notificationName,
      scheduled_date: payloadStartDate || "",
      scheduled_time: NotificationTimeSplit[4] || "",
      employee_class: transformedList || [["All"]],
      content: e.notificationContent || "",
      status: "scheduled",
      time_zone: zone_name,
      ...(notificationImageId
        ? { image: notificationImageId }
        : e.uploadThumbnail
        ? {}
        : { image: "" })
    }
    if (isEdit !== false) {
      dispatch(EditNotificationAction(payload))
    } else {
      dispatch(addNotificationAction(payload))
    }
    onClose()
  }

  useEffect(() => {
    dispatch(
      getNotificationListAction({
        page: currentPage,
        orderBy: orderBy
      })
    )
  }, [currentPage, orderBy])

  useEffect(() => {
    setCurrentPage(1)
  }, [orderBy])

  useEffect(() => {}, [notificationList?.data])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Notifications"
    dispatch(getUserRoleAction())
  }, [])
  return (
    <>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Header title="Notification Engine" breadCrumbs={breadCrumbs}>
              <AppButton onClick={() => handleAddNotification()} sx={{ padding: "13px 36px" }}>
                + Add New Notification
              </AppButton>
            </Header>
          </Grid>
        </Grid>
        <Container style={{ minWidth: "100%" }}>
          <Row style={{ width: "100%", overflow: "auto" }}>
            <Col lg="12" className="p-0">
              <NotificationListTable
                setPage={setCurrentPage}
                currentPage={currentPage}
                totalPage={notificationList?.totalPages}
                rows={notificationList?.data}
                columns={columns}
                handleEditNotification={handleEditNotification}
              />
            </Col>
          </Row>
        </Container>
        {openModal && (
          <Formik
            onSubmit={handleSubmit}
            validationSchema={validationSchemaNotification}
            initialValues={isEdit ? initialValuesEdit : initialValues}>
            {(formik) => (
              <NotificationForm
                isEdit={isEdit}
                onClose={onClose}
                formik={formik}
                open={openModal}
                notificationData={notificationData}
              />
            )}
          </Formik>
        )}
      </Grid>
    </>
  )
}

export default SuperAdminNotification

const columns = [
  {
    id: "Notification Name",
    label: "Notification Name",
    minWidth: 160
  },
  {
    id: "Notification Content",
    label: "Notification Content",
    minWidth: 170
  },
  {
    id: "Role",
    label: "Role",
    align: "center",
    minWidth: 150
  },
  {
    id: "Notification Date & Time",
    label: "Notification Date & Time",
    align: "center",
    minWidth: 240,
    isSorting: true
  },
  {
    id: "image",
    label: "Image",
    minWidth: 80,
    align: "center"
  },
  {
    id: "Status",
    label: "Status",
    align: "center",
    minWidth: 120
  },
  {
    id: "Resend Notification",
    label: "Resend Notification",
    align: "center",
    minWidth: 160
  },
  {
    id: "Actions",
    label: "Actions",
    align: "center",
    minWidth: 80
  }
]
