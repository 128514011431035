import SubButtonNav from "components/SubMenuNavButton"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import { Col, Row } from "react-bootstrap"
import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import FormControl from "@mui/material/FormControl"

const menuText = {
  DINNER: "dinner",
  LUNCH: "lunch",
  BRUNCH: "brunch"
}

function SubMenuBar(props) {
  const [menu] = useState(props.menu)
  const dispatch = useDispatch()
  const { linkCategory } = useSelector((state) => state?.marketing?.menu || {})

  const handleChange = (event) => {
    dispatch(marketingActions.setFilterListValue(event.target.value))
  }

  function handleButtonNav(name) {
    let itemsLink = {}
    if (props.isEdit) {
      itemsLink = {
        item: linkCategory?.item,
        category: name,
        list: "Detailed View"
      }
    } else {
      itemsLink = {
        item: linkCategory?.item,
        category: name,
        list: "Summary View"
      }
    }

    dispatch(marketingActions.setMenuLink(itemsLink))
  }

  return (
    <>
      {(linkCategory?.item === menuText.DINNER ||
        linkCategory?.item === menuText.LUNCH ||
        linkCategory?.item === menuText.BRUNCH) &&
        menu?.map((item, i) => {
          return (
            <SubButtonNav
              style={{ marginRight: 12 }}
              onPress={() => handleButtonNav(item?.name)}
              key={i}
              isActive={props.activeTab === item.name ? true : false}>
              {item.name}
            </SubButtonNav>
          )
        })}
      <Row className="mt-4">
        <Col lg="12">
          {props.isEdit ? null : (
            <FormControl variant="filled">
              <RadioGroup
                className="d-flex flex-row"
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={linkCategory?.list}
                onChange={(e) => {
                  handleChange(e)
                }}>
                <FormControlLabel
                  value="Summary View"
                  control={<Radio color="secondary" />}
                  label="Summary View"
                />
                <FormControlLabel
                  value="Detailed View"
                  control={<Radio color="secondary" />}
                  label="Detailed View"
                />
              </RadioGroup>
            </FormControl>
          )}
        </Col>
      </Row>
    </>
  )
}

export default SubMenuBar
