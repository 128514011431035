import { Formik } from "formik"
import { generateDynamicValidations } from "helpers/validationSchema"
import React, { useEffect } from "react"
import MenuItemsForm from "./components/MenuItemForm"
import { useDispatch, useSelector } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import {
  addBarMenuItemsAction,
  addMenuItemsActions,
  addWineMenuItemsAction,
  updateBarItemsAction,
  updateFoodItemsAction,
  updateWineItemsAction
} from "redux/marketing/actions"
import moment from "moment"
import { getDynamicMenuFieldsAction } from "redux/superAdmin/actions"
import { toTitleCase } from "helpers/functions"

const menuText = {
  BARMENU: "bar menu",
  WINEBAR: "wine by glass",
  DINNER: "dinner",
  LUNCH: "lunch",
  BRUNCH: "brunch"
}

function getMenuDescription(desc) {
  let items = []
  if (!desc) return []
  items = desc?.split(",")
  return items
}

function getAllergence(allergence) {
  let allergenceItems = []
  if (!allergence) return []
  for (let al of allergence) {
    allergenceItems.push(al?.name)
  }
  return allergenceItems
}

function getTestingNotes(testing) {
  let testingItems = []
  if (!testing) return []
  for (let al of testing) {
    testingItems.push(al?.name)
  }
  return testingItems
}

function AddMenuItems({ open = false, onClose = () => {}, isEdit = false }) {
  const { menu_Category_ID, linkCategory, menuItemImageId, foodItem, bar_category } = useSelector(
    (store) => store?.marketing?.menu ?? {}
  )
  const dispatch = useDispatch()
  const zone_name = moment.tz.guess()
  const { menuFields } = useSelector((store) => store?.superAdmin || [])

  function initDateConvert(date, time) {
    const initDate = moment(date).format("ddd MMM DD YYYY") + " " + time
    const temp = new Date(initDate)
    const convertedUTCDate = new Date(
      Date.UTC(
        temp.getFullYear(),
        temp.getMonth(),
        temp.getDate(),
        temp.getHours(),
        temp.getMinutes(),
        temp.getSeconds()
      )
    )
    return moment(convertedUTCDate).local().toDate()
  }

  function setInitialFormValue() {
    const defaultValues = menuFields?.reduce((acc, field) => {
      if (field.label === "unique_facts" || field.label === "glossary") {
        acc[field.label] = [{ fact: "" }]
      } else if (
        field.label === "allergens" ||
        field.label === "menu_description" ||
        field.label === "tasting_notes"
      ) {
        acc[field.label] = []
      } else if (field.label === "ingredients") {
        acc[field.label] = [{ name: "", measurement: "" }]
      } else {
        acc[field.label] = ""
      }
      return acc
    }, {})

    const ingredientObjects =
      typeof foodItem?.ingredients === "string" &&
      foodItem?.ingredients?.split(",").map((ingredient) => ({
        name: ingredient.trim(),
        measurement: ""
      }))

    return menuFields?.reduce((acc, field) => {
      if (!isEdit || !foodItem.id) {
        // Return default values if not editing or no foodItem ID
        return defaultValues
      }

      switch (field.label) {
        case "image":
          acc[field.label] = foodItem?.image
            ? { id: foodItem?.image_id, url: foodItem?.image ?? "" }
            : ""
          break
        case "tasting_notes":
          acc[field.label] = getTestingNotes(foodItem?.tasting_notes ?? [])
          break
        case "notification_time":
          acc[field.label] = foodItem?.notification_time
            ? moment(
                initDateConvert(foodItem.notification_date, foodItem.notification_time)
              ).format("HH:mm:ss")
            : ""
          break
        case "menu_description":
          acc[field.label] = getMenuDescription(foodItem?.menu_description)
          break
        case "allergance":
          acc[field.label] = getAllergence(foodItem?.allergance ?? [])
          break
        case "allergens":
          acc[field.label] = getAllergence(foodItem?.allergens ?? [])
          break
        case "unique_facts":
          acc[field.label] =
            foodItem?.unique_facts?.length > 0 ? foodItem?.unique_facts : [{ fact: "" }]
          break
        case "glossary":
          acc[field.label] = foodItem?.glossary?.length > 0 ? foodItem?.glossary : [{ fact: "" }]
          break
        case "ingredients":
          acc[field.label] =
            typeof foodItem?.ingredients === "string"
              ? ingredientObjects
              : Array.isArray(foodItem?.ingredients)
              ? foodItem.ingredients
              : [{ name: "", measurement: "" }]
          break
        default:
          acc[field.label] = foodItem?.[field.label] || ""
          break
      }

      return acc
    }, defaultValues)
  }

  function setInitialBarFormValue() {
    const defaultValues = menuFields?.reduce((acc, field) => {
      if (field.label === "unique_facts" || field.label === "glossary") {
        acc[field.label] = [{ fact: "" }]
      } else if (
        field.label === "allergens" ||
        field.label === "menu_description" ||
        field.label === "tasting_notes" ||
        field.label === "ice"
      ) {
        acc[field.label] = []
      } else if (field.label === "ingredients") {
        acc[field.label] = [{ name: "", measurement: "" }]
      } else {
        acc[field.label] = ""
      }
      return acc
    }, {})
    let notificationTime = ""

    if (foodItem?.notification_date && foodItem?.notification_time) {
      const notificationTimeStamp = initDateConvert(
        foodItem.notification_date,
        foodItem.notification_time
      )
      notificationTime = moment(notificationTimeStamp).format("HH:mm:ss")
    }

    const ingredientObjects =
      typeof foodItem?.ingredients === "string" &&
      foodItem?.ingredients?.split(",").map((ingredient) => ({
        name: ingredient.trim(),
        measurement: ""
      }))

    return menuFields?.reduce((acc, field) => {
      if (!isEdit || !foodItem.id) {
        // Return default values if not editing or no foodItem ID
        return defaultValues
      }

      switch (field.label) {
        case "image":
          acc[field.label] = foodItem?.image
            ? { id: foodItem?.image, url: foodItem?.image_url ?? "" }
            : ""
          break
        case "menu_description":
          acc[field.label] = getMenuDescription(foodItem?.menu_description)
          break
        case "allergance":
          acc[field.label] = getAllergence(foodItem?.allergance ?? [])
          break
        case "ice":
          acc[field.label] = getAllergence(foodItem?.ice ?? [])
          break
        case "allergens":
          acc[field.label] = getAllergence(foodItem?.allergens ?? [])
          break
        case "notification_time":
          acc[field.label] = foodItem?.notification_time ? notificationTime : ""
          break
        case "unique_facts":
          acc[field.label] =
            foodItem?.unique_facts?.length > 0 ? foodItem?.unique_facts : [{ fact: "" }]
          break
        case "glossary":
          acc[field.label] = foodItem?.glossary?.length > 0 ? foodItem?.glossary : [{ fact: "" }]
          break
        case "ingredients":
          acc[field.label] =
            typeof foodItem?.ingredients === "string"
              ? ingredientObjects
              : Array.isArray(foodItem?.ingredients)
              ? foodItem.ingredients
              : [{ name: "", measurement: "" }]
          break
        case "tasting_notes":
          acc[field.label] = getTestingNotes(foodItem?.tasting_notes ?? [])
          break
        default:
          acc[field.label] = foodItem?.[field.label] || ""
          break
      }

      return acc
    }, defaultValues)
  }

  function setInitialBeerFormValue() {
    const defaultValues = menuFields?.reduce((acc, field) => {
      if (field.label === "tasting_notes") {
        acc[field.label] = []
      } else if (field.label === "unique_facts" || field.label === "glossary") {
        acc[field.label] = [{ fact: "" }]
      } else if (
        field.label === "allergens" ||
        field.label === "menu_description" ||
        field.label === "ice"
      ) {
        acc[field.label] = []
      } else if (field.label === "ingredients") {
        acc[field.label] = [{ name: "", measurement: "" }]
      } else {
        acc[field.label] = ""
      }
      return acc
    }, {})
    let notificationTime = ""

    if (foodItem?.notification_date && foodItem?.notification_time) {
      const notificationTimeStamp = initDateConvert(
        foodItem.notification_date,
        foodItem.notification_time
      )
      notificationTime = moment(notificationTimeStamp).format("HH:mm:ss")
    }

    const ingredientObjects =
      typeof foodItem?.ingredients === "string" &&
      foodItem?.ingredients?.split(",").map((ingredient) => ({
        name: ingredient.trim(),
        measurement: ""
      }))

    return menuFields?.reduce((acc, field) => {
      if (!isEdit || !foodItem.id) {
        // Return default values if not editing or no foodItem ID
        return defaultValues
      }

      switch (field.label) {
        case "image":
          acc[field.label] = foodItem?.image
            ? { id: foodItem?.image, url: foodItem?.image_url ?? "" }
            : ""
          break
        case "tasting_notes":
          acc[field.label] = getTestingNotes(foodItem?.tasting_notes ?? [])
          break
        case "notification_time":
          acc[field.label] = foodItem?.notification_time ? notificationTime : ""
          break
        case "menu_description":
          acc[field.label] = getMenuDescription(foodItem?.menu_description)
          break
        case "ice":
          acc[field.label] = getAllergence(foodItem?.ice ?? [])
          break
        case "allergance":
          acc[field.label] = getAllergence(foodItem?.allergance ?? [])
          break
        case "allergens":
          acc[field.label] = getAllergence(foodItem?.allergens ?? [])
          break
        case "unique_facts":
          acc[field.label] =
            foodItem?.unique_facts?.length > 0 ? foodItem?.unique_facts : [{ fact: "" }]
          break
        case "glossary":
          acc[field.label] = foodItem?.glossary?.length > 0 ? foodItem?.glossary : [{ fact: "" }]
          break
        case "ingredients":
          acc[field.label] =
            typeof foodItem?.ingredients === "string"
              ? ingredientObjects
              : Array.isArray(foodItem?.ingredients)
              ? foodItem.ingredients
              : [{ name: "", measurement: "" }]
          break
        default:
          acc[field.label] = foodItem?.[field.label] || ""
          break
      }

      return acc
    }, defaultValues)
  }

  function setInitialWineFormValue() {
    const defaultValues = menuFields?.reduce((acc, field) => {
      if (field.label === "tasting_notes") {
        acc[field.label] = []
      } else {
        acc[field.label] = ""
      }
      return acc
    }, {})

    let notificationTime = ""

    if (foodItem?.notification_date && foodItem?.notification_time) {
      const notificationTimeStamp = initDateConvert(
        foodItem.notification_date,
        foodItem.notification_time
      )
      notificationTime = moment(notificationTimeStamp).format("HH:mm:ss")
    }

    const ingredientObjects =
      typeof foodItem?.ingredients === "string" &&
      foodItem?.ingredients?.split(",").map((ingredient) => ({
        name: ingredient.trim(),
        measurement: ""
      }))

    return menuFields?.reduce((acc, field) => {
      if (!isEdit || !foodItem.id) {
        // Return default values if not editing or no foodItem ID
        return defaultValues
      }
      switch (field.label) {
        case "image":
          acc[field.label] = foodItem?.image
            ? { id: foodItem?.image, url: foodItem?.image_url ?? "" }
            : ""
          break
        case "tasting_notes":
          acc[field.label] = getTestingNotes(foodItem?.tasting_notes ?? [])
          break
        case "notification_time":
          acc[field.label] = foodItem?.notification_time ? notificationTime : ""
          break
        case "menu_description":
          acc[field.label] = getMenuDescription(foodItem?.menu_description)
          break
        case "allergance":
          acc[field.label] = getAllergence(foodItem?.allergance ?? [])
          break
        case "allergens":
          acc[field.label] = getAllergence(foodItem?.allergens ?? [])
          break
        case "unique_facts":
          acc[field.label] =
            foodItem?.unique_facts?.length > 0 ? foodItem?.unique_facts : [{ fact: "" }]
          break
        case "glossary":
          acc[field.label] = foodItem?.glossary?.length > 0 ? foodItem?.glossary : [{ fact: "" }]
          break
        case "ingredients":
          acc[field.label] =
            typeof foodItem?.ingredients === "string"
              ? ingredientObjects
              : Array.isArray(foodItem?.ingredients)
              ? foodItem.ingredients
              : [{ name: "", measurement: "" }]
          break
        default:
          acc[field.label] = foodItem?.[field.label] || ""
          break
      }

      return acc
    }, defaultValues)
  }

  useEffect(() => {
    dispatch(marketingActions.setMenuImageId(""))
  }, [])

  function convertDate(date, time) {
    const forDate = moment(new Date(date)).format("ddd MMM DD YYYY") + " " + time
    const newDate = new Date(forDate)
    return newDate.toUTCString()
  }
  function payloadDate(dateArray = []) {
    return `${dateArray[3]}-${months[dateArray[2]]}-${dateArray[1]}`
  }

  function handleSubmit(e) {
    let payloadStartDate = null
    let NotificationTimeSplit = null
    if (e.notification_date && e.notification_time) {
      const NotificationTimeStamp = convertDate(e.notification_date, e.notification_time)
      NotificationTimeSplit = NotificationTimeStamp.split(" ")
      payloadStartDate = payloadDate(NotificationTimeSplit)
    }

    const dynamicPayload = menuFields.reduce((acc, field) => {
      const fieldValue = (() => {
        switch (field.label) {
          case "notification_date":
            return payloadStartDate || null
          case "notification_time":
            return NotificationTimeSplit ? NotificationTimeSplit[4] : null
          case "allergance":
            return isEdit === false && !e.id
              ? e.allergance
              : e.allergance?.flatMap((items) => items)
          case "allergens":
            return isEdit === false && !e.id ? e.allergens : e.allergens?.flatMap((items) => items)
          case "item_price":
            return e.item_price !== "" ? e.item_price : null
          case "price":
            return e.price !== "" ? e.price : null
          case "image":
            return menuItemImageId ? menuItemImageId : e.image ? e?.image?.id : ""
          case "unique_facts":
            return e?.unique_facts ? e?.unique_facts.filter((fact) => fact.fact.trim() !== "") : ""
          case "glossary":
            return e?.glossary ? e?.glossary.filter((fact) => fact.fact.trim() !== "") : ""
          case "ingredients":
            return e?.ingredients ? e?.ingredients : []
          default:
            return e?.[field.label]
        }
      })()

      acc[field.label] = fieldValue
      return acc
    }, {})

    const payload = {
      ...dynamicPayload,
      meal_period: linkCategory?.item,
      category: menu_Category_ID,
      time_zone: zone_name
    }

    if (isEdit === false && !foodItem.id) {
      dispatch(addMenuItemsActions({ payload: payload, onClose: onClose }))
    } else {
      dispatch(updateFoodItemsAction({ payload: payload, onClose: onClose }))
    }
  }

  function handleSubmitWineItem(e) {
    let payloadStartDate = null
    let NotificationTimeSplit = null
    if (e.notification_date && e.notification_time) {
      const NotificationTimeStamp = convertDate(e.notification_date, e.notification_time)
      NotificationTimeSplit = NotificationTimeStamp.split(" ")
      payloadStartDate = payloadDate(NotificationTimeSplit)
    }

    const dynamicPayload = menuFields.reduce((acc, field) => {
      const fieldValue = (() => {
        switch (field.label) {
          case "notification_date":
            return payloadStartDate || null
          case "notification_time":
            return NotificationTimeSplit ? NotificationTimeSplit[4] : null
          case "item_price":
            return e.item_price !== "" ? e.item_price : null
          case "price":
            return e.price !== "" ? e.price : null
          case "image":
            return menuItemImageId ? menuItemImageId : e.image ? e?.image?.id : ""
          case "allergance":
            return isEdit === false && !e.id
              ? e.allergance
              : e.allergance?.flatMap((items) => items)
          case "allergens":
            return isEdit === false && !e.id ? e.allergens : e.allergens?.flatMap((items) => items)
          case "unique_facts":
            return e?.unique_facts ? e?.unique_facts.filter((fact) => fact.fact.trim() !== "") : ""
          case "glossary":
            return e?.glossary ? e?.glossary.filter((fact) => fact.fact.trim() !== "") : ""
          case "ingredients":
            return e?.ingredients ? e?.ingredients : []
          case "ice":
            return e?.ice ? e?.ice : []
          default:
            return e?.[field.label]
        }
      })()

      acc[field.label] = fieldValue
      return acc
    }, {})

    const payload = {
      ...dynamicPayload,
      meal_period: linkCategory?.item,
      time_zone: zone_name,
      sub_category: menu_Category_ID
    }

    if (isEdit === false && !foodItem.id) {
      dispatch(addWineMenuItemsAction({ payload: payload, onClose: onClose }))
    } else {
      dispatch(updateWineItemsAction({ payload: payload, onClose: onClose }))
    }
  }

  function handleSubmitBarItem(e) {
    let payloadStartDate = null
    let NotificationTimeSplit = null
    if (e.notification_date && e.notification_time) {
      const NotificationTimeStamp = convertDate(e.notification_date, e.notification_time)
      NotificationTimeSplit = NotificationTimeStamp.split(" ")
      payloadStartDate = payloadDate(NotificationTimeSplit)
    }

    const dynamicPayload = menuFields.reduce((acc, field) => {
      const fieldValue = (() => {
        switch (field.label) {
          case "notification_date":
            return payloadStartDate || null
          case "notification_time":
            return NotificationTimeSplit ? NotificationTimeSplit[4] : null
          case "item_price":
            return e.item_price !== "" ? e.item_price : null
          case "price":
            return e.price !== "" ? e.price : null
          case "image":
            return menuItemImageId ? menuItemImageId : e.image ? e?.image?.id : ""
          case "unique_facts":
            return e?.unique_facts ? e?.unique_facts.filter((fact) => fact.fact.trim() !== "") : ""
          case "glossary":
            return e?.glossary ? e?.glossary.filter((fact) => fact.fact.trim() !== "") : ""
          case "allergance":
            return isEdit === false && !e.id
              ? e.allergance
              : e.allergance?.flatMap((items) => items)
          case "allergens":
            return isEdit === false && !e.id ? e.allergens : e.allergens?.flatMap((items) => items)
          case "ingredients":
            return e?.ingredients ? e?.ingredients : []
          case "ice":
            return e?.ice ? e?.ice : []
          default:
            return e?.[field.label]
        }
      })()

      acc[field.label] = fieldValue
      return acc
    }, {})

    const payload = {
      ...dynamicPayload,
      meal_period: "bar menu",
      bar_category: menu_Category_ID,
      time_zone: zone_name
    }
    if (isEdit === false && !foodItem.id) {
      dispatch(addBarMenuItemsAction({ payload: payload, onClose: onClose }))
    } else {
      dispatch(updateBarItemsAction({ payload: payload, onClose: onClose }))
    }
  }

  function handleSubmitBeerItem(e) {
    let payloadStartDate = null
    let NotificationTimeSplit = null

    if (e.notification_date && e.notification_time) {
      const NotificationTimeStamp = convertDate(e.notification_date, e.notification_time)
      NotificationTimeSplit = NotificationTimeStamp.split(" ")
      payloadStartDate = payloadDate(NotificationTimeSplit)
    }

    const dynamicPayload = menuFields.reduce((acc, field) => {
      const fieldValue = (() => {
        switch (field.label) {
          case "notification_date":
            return payloadStartDate || null
          case "notification_time":
            return NotificationTimeSplit ? NotificationTimeSplit[4] : null
          case "item_price":
            return e.item_price !== "" ? e.item_price : null
          case "price":
            return e.price !== "" ? e.price : null
          case "abv":
            return e.abv !== "" ? e.abv : null
          case "image":
            return menuItemImageId ? menuItemImageId : e.image ? e?.image?.id : ""
          case "unique_facts":
            return e?.unique_facts ? e?.unique_facts.filter((fact) => fact.fact.trim() !== "") : ""
          case "glossary":
            return e?.glossary ? e?.glossary.filter((fact) => fact.fact.trim() !== "") : ""
          case "allergance":
            return isEdit === false && !e.id
              ? e.allergance
              : e.allergance?.flatMap((items) => items)
          case "allergens":
            return isEdit === false && !e.id ? e.allergens : e.allergens?.flatMap((items) => items)
          case "ingredients":
            return e?.ingredients ? e?.ingredients : []
          case "ice":
            return e?.ice ? e?.ice : []
          default:
            return e?.[field.label]
        }
      })()

      acc[field.label] = fieldValue
      return acc
    }, {})

    const payload = {
      ...dynamicPayload,
      meal_period: "bar menu",
      bar_category: menu_Category_ID,
      time_zone: zone_name
    }
    if (isEdit === false && !foodItem.id) {
      dispatch(addBarMenuItemsAction({ payload: payload, onClose: onClose }))
    } else {
      dispatch(updateBarItemsAction({ payload: payload, onClose: onClose }))
    }
  }

  useEffect(() => {
    if (linkCategory?.item === menuText.BARMENU) {
      dispatch(
        getDynamicMenuFieldsAction({
          category: toTitleCase(linkCategory?.item),
          sub_category: bar_category
        })
      )
    }
  }, [linkCategory?.item, bar_category])

  function createDyanmicValidation(fields, item) {
    const dynamiValidation = generateDynamicValidations(fields, item)
    return dynamiValidation
  }

  return (
    <>
      {(linkCategory?.item === menuText.LUNCH ||
        linkCategory?.item === menuText.DINNER ||
        linkCategory?.item === menuText.BRUNCH) && (
        <Formik
          initialValues={setInitialFormValue()}
          enableReinitialize={true}
          validationSchema={createDyanmicValidation(menuFields, "Dish")}
          onSubmit={handleSubmit}>
          {(formik) => (
            <MenuItemsForm
              isEdit={isEdit}
              onClose={onClose}
              formik={formik}
              open={open}
              menuFields={menuFields}
            />
          )}
        </Formik>
      )}
      {linkCategory?.item === menuText.BARMENU &&
        (bar_category?.toLowerCase() !== "beer" ? (
          <Formik
            initialValues={setInitialBarFormValue()}
            enableReinitialize={true}
            validationSchema={createDyanmicValidation(menuFields, "Cocktail")}
            onSubmit={handleSubmitBarItem}>
            {(formik) => (
              <>
                <MenuItemsForm
                  isEdit={isEdit}
                  onClose={onClose}
                  formik={formik}
                  open={open}
                  title="Bar Item"
                  menuFields={menuFields}
                />
              </>
            )}
          </Formik>
        ) : (
          <Formik
            initialValues={setInitialBeerFormValue()}
            enableReinitialize={true}
            validationSchema={createDyanmicValidation(menuFields, "Beer")}
            onSubmit={handleSubmitBeerItem}>
            {(formik) => (
              <>
                <MenuItemsForm
                  isEdit={isEdit}
                  onClose={onClose}
                  formik={formik}
                  open={open}
                  title="Beer Item"
                  menuFields={menuFields}
                />
              </>
            )}
          </Formik>
        ))}
      {linkCategory?.item === menuText.WINEBAR && (
        <Formik
          initialValues={setInitialWineFormValue()}
          enableReinitialize={true}
          validationSchema={createDyanmicValidation(menuFields, "Wine")}
          onSubmit={handleSubmitWineItem}>
          {(formik) => (
            <>
              <MenuItemsForm
                isEdit={isEdit}
                onClose={onClose}
                formik={formik}
                open={open}
                title="Wine Item"
                menuFields={menuFields}
              />
            </>
          )}
        </Formik>
      )}
    </>
  )
}

export default AddMenuItems
const months = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12"
}
