import { createSlice } from "@reduxjs/toolkit"
import { formatDate } from "helpers/functions"

const app = createSlice({
  name: "app",
  initialState: {
    loading: false,
    message: {
      text: "",
      variant: ""
    },
    isLoggedIn: false,
    filters: {
      date: formatDate(new Date()),
      restaurant: "All",
      searchField: "",
      alcohol_type: "All",
      giftCard_type: "All",
      rank: [],
      category: []
    },
    restaurantList: [],
    userTypeList: [],
    adminUsersList: [],
    roleUsers: [],
    newRestaurantList: [],
    isLoading: false,
    isUpdating: false,
    isMultiRestaurant: false
  },
  reducers: {
    setLoadingData: (state, action) => {
      state.loading = action.payload
    },
    setMessageData: (state, action) => {
      state.message = action.payload
    },
    setIsLoggedInData: (state, action) => {
      state.isLoggedIn = action.payload
    },
    initFilters: (state, action) => {
      state.filters = {
        date: action.payload ? formatDate(action.payload.date) : formatDate(new Date()),
        searchField: "",
        restaurant: "All",
        alcohol_type: "All",
        giftCard_type: "All",
        rank: [],
        category: []
      }
    },
    setInitDate: (state, action) => {
      state.filters.date = action.payload
    },
    setInitAlcoholType: (state, action) => {
      state.filters.alcohol_type = action.payload
    },
    setInitgiftCardType: (state, action) => {
      state.filters.giftCard_type = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload
      }
    },
    setRestaurantList: (state, action) => {
      state.restaurantList = action.payload
    },
    setUserTypeList: (state, action) => {
      state.userTypeList = action.payload
    },
    setAdminUserList: (state, action) => {
      state.adminUsersList = action.payload
    },
    setRolesUserList: (state, action) => {
      state.roleUsers = action.payload
    },
    setNewRestaurantList: (state, action) => {
      state.newRestaurantList = action.payload
    },
    setSearchField: (state, action) => {
      state.filters.searchField = action.payload
    },
    setOrderBy: (state, action) => {
      state.filters.orderBy = action.payload
    },
    setIsUpdating: (state, action) => {
      state.isUpdating = action.payload
    },
    setIsMultiRestaurant: (state, action) => {
      state.isMultiRestaurant = action.payload
    }
  }
})

export default app
export const appActions = app.actions
