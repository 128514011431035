import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import CreateIcon from "@mui/icons-material/Create"
import { useDispatch, useSelector } from "react-redux"
import classes from "./menu.module.scss"
import MenuListContainer from "../../../../components/menuListContainer/index"
import {
  getBarMenuPeriodAction,
  getMenuPeriodAction,
  getWineMenuPeriodAction
} from "redux/marketing/actions"
import { Grid, InputAdornment, Typography, useTheme } from "@mui/material"
import { useHistory } from "react-router-dom"
import { marketingActions } from "redux/marketing/slice/user"
import MenuNavBar from "components/menuNavBar"
import SubMenuBar from "components/subMenuBar"
import WineListItems from "components/WineListItems"
import BarListItems from "components/BarListItem"
import {
  getMenuSubCategoryAction,
  getNewSpiritCountAction,
  getSpiritCMSAction
} from "redux/marketing/actions/user"
import SpiritsMenuListContainer from "components/spiritsMenuListConatiner"
import SpiritsSubMenuBar from "components/SpiritsSubMenuBar"
import DMV_SpiritSummary from "components/DMVSpiritSummary"
import ImportSpiritsModal from "components/ImportSpirits"
import UploadAddMenuModal from "./BulkUpload/UploadAddMenuModal"
import { useDebounce } from "hooks/utils"
import SearchTextField from "components/StyledComponents/SearchTextField"
import SearchIcon from "@mui/icons-material/Search"

const menuText = {
  BARMENU: "bar menu",
  WINEBAR: "wine by glass",
  DINNER: "dinner",
  LUNCH: "lunch",
  BRUNCH: "brunch",
  SPIRITS: "spirits"
}

function Menu() {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const { linkCategory } = useSelector((state) => state?.marketing?.menu || {})
  const { menuPeriod, barMenuPeriod, wineMenuPeriod, spiritData, menuSubCategory } = useSelector(
    (state) => state?.marketing?.menu || []
  )
  const { menuCategory, SpiritSubMenu } = useSelector((state) => state.dmv)
  const [importSpiritModal, setImportSpiritModal] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const debouncedSearchTerm = useDebounce(searchValue, 500)
  const [menuItems, setMenuItems] = useState(menuPeriod)
  const [barItems, setBarItems] = useState(barMenuPeriod)
  const [wineItems, setWineItems] = useState(wineMenuPeriod)
  const [spiritItems, setSpiritItems] = useState(spiritData)

  const handleSearchField = (e) => {
    setSearchValue(e.target.value)
  }

  const handleRouting = () => {
    const itemsLink = {
      item: linkCategory?.item,
      category: linkCategory?.category,
      list: "Detailed View"
    }
    dispatch(marketingActions.setMenuLink(itemsLink))
    history.push("/marketing/dashboard/explore-menu/edit")
  }

  const handleArchivedRouting = () => {
    dispatch(marketingActions.setArchivedMenu(true))
    history.push("/marketing/dashboard/explore-menu/archived-items")
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Menu"
  }, [])

  useEffect(() => {
    if (linkCategory?.item === menuText.BARMENU) {
      dispatch(getBarMenuPeriodAction())
    } else if (linkCategory?.item === menuText.WINEBAR) {
      dispatch(getWineMenuPeriodAction())
    } else if (linkCategory?.item === menuText.SPIRITS) {
      dispatch(getSpiritCMSAction())
      dispatch(getNewSpiritCountAction())
    } else {
      dispatch(getMenuSubCategoryAction())
      dispatch(getMenuPeriodAction())
    }
    setSearchValue("")
  }, [linkCategory?.item, linkCategory?.category])

  function handleImportSpirit() {
    setImportSpiritModal(true)
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  function filterResults(searchQuery, results, menuType) {
    const trimmedQuery = searchQuery.trim().toLowerCase()

    if (!trimmedQuery) {
      return results
    }

    const isSpiritsMenu = menuType === menuText.SPIRITS
    const listKey = isSpiritsMenu ? "spirit_list" : "food_list"
    const categoryKey = isSpiritsMenu ? "spirit_category" : "food_category"

    return results
      ?.map((period) => {
        const category = period[categoryKey]?.toLowerCase()
        const isCategoryMatch = category.includes(trimmedQuery)

        // If the search query partially matches the category, return all items in that category
        if (isCategoryMatch) {
          return period
        }

        // Filter items by name or by ingredients
        const filteredList = period[listKey]?.filter((item) => {
          const nameMatch = item?.name?.toLowerCase().includes(trimmedQuery)
          let fieldMatches
          if (isSpiritsMenu) {
            const searchableFields = [
              "description",
              "origin",
              "unique_facts",
              "ingredient",
              "nose",
              "palate",
              "finish",
              "distillation",
              "aging",
              "bottling"
            ]

            fieldMatches = searchableFields.some((field) => {
              if (typeof item[field] === "string") {
                return item[field].toLowerCase().includes(trimmedQuery)
              }

              if (Array.isArray(item[field])) {
                return item[field].some((value) => {
                  if (typeof value === "string") {
                    return value.toLowerCase().includes(trimmedQuery)
                  } else if (typeof value === "object" && value?.fact) {
                    return value.fact.toLowerCase().includes(trimmedQuery)
                  } else if (typeof value === "object" && value?.name) {
                    return value.name.toLowerCase().includes(trimmedQuery)
                  }
                  return false
                })
              }
              return false
            })
          }

          // Dynamically search in the data_to_display fields
          const dataFieldMatch = item?.data_to_display?.some((field) => {
            const fieldValue = field?.value

            if (typeof fieldValue === "string") {
              return fieldValue.toLowerCase().includes(trimmedQuery)
            }

            // If the value is an array, search within the array elements (e.g., allergens or ingredients)
            if (Array.isArray(fieldValue)) {
              return fieldValue.some((value) => {
                if (typeof value === "string") {
                  return value.toLowerCase().includes(trimmedQuery)
                } else if (typeof value === "object" && value?.name) {
                  return value.name.toLowerCase().includes(trimmedQuery)
                }
                return false
              })
            }

            if (typeof fieldValue === "object" && fieldValue?.name) {
              return fieldValue.name.toLowerCase().includes(trimmedQuery)
            }

            return false
          })

          return nameMatch || dataFieldMatch || fieldMatches
        })

        return filteredList?.length > 0 ? { ...period, [listKey]: filteredList } : null
      })
      .filter(Boolean)
      .sort((a, b) => {
        const categoryA = a[categoryKey].toLowerCase()
        const categoryB = b[categoryKey].toLowerCase()

        // Sort based on how closely the category matches the query
        if (categoryA === trimmedQuery) return -1
        if (categoryB === trimmedQuery) return 1

        if (categoryA.startsWith(trimmedQuery) && !categoryB.startsWith(trimmedQuery)) return -1
        if (!categoryA.startsWith(trimmedQuery) && categoryB.startsWith(trimmedQuery)) return 1

        return categoryA.localeCompare(categoryB)
      })
  }

  useEffect(() => {
    const menuMap = {
      [menuText.BARMENU]: {
        data: barMenuPeriod,
        setFunction: setBarItems
      },
      [menuText.WINEBAR]: {
        data: wineMenuPeriod,
        setFunction: setWineItems
      },
      [menuText.SPIRITS]: {
        data: spiritData,
        setFunction: setSpiritItems
      },
      default: {
        data: menuPeriod,
        setFunction: setMenuItems
      }
    }

    const { data, setFunction } = menuMap[linkCategory?.item] || menuMap.default
    const filteredResults = filterResults(debouncedSearchTerm, data, linkCategory?.item)
    setFunction(filteredResults)
  }, [
    barMenuPeriod,
    wineMenuPeriod,
    spiritData,
    menuPeriod,
    linkCategory?.item,
    menuCategory?.name,
    linkCategory?.category,
    debouncedSearchTerm
  ])

  return (
    <Fragment>
      <UploadAddMenuModal open={modalOpen} onClose={handleClose} />
      {importSpiritModal && (
        <ImportSpiritsModal open={importSpiritModal} onClose={() => setImportSpiritModal(false)} />
      )}
      <Container fluid className="p-0">
        <Row>
          <Col lg="12">
            <Header title={`Menu`} breadCrumbs={breadCrumbs}>
              <Grid container spacing={2}>
                <Grid item>
                  {linkCategory?.item !== menuText.SPIRITS ? (
                    <AppButton variant="contained" onClick={() => setModalOpen(true)}>
                      + Bulk Upload
                    </AppButton>
                  ) : null}
                </Grid>
                <Grid item>
                  <AppButton variant="contained" onClick={handleArchivedRouting}>
                    Archived Items
                  </AppButton>
                </Grid>
                {linkCategory?.item === menuText.SPIRITS && (
                  <Grid item sx={{ position: "relative" }}>
                    <AppButton variant="contained" onClick={handleImportSpirit}>
                      Import Spirit
                    </AppButton>
                    {linkCategory?.item === menuText.SPIRITS &&
                      SpiritSubMenu?.[0]?.dmv_total_count && (
                        <Grid item sx={{ top: "6px" }} className={classes.ellipse_gold}>
                          {SpiritSubMenu?.[0]?.dmv_total_count}
                        </Grid>
                      )}
                  </Grid>
                )}
                <Grid item>
                  <Grid sx={{ position: "relative" }}>
                    <AppButton onClick={handleRouting}>
                      <CreateIcon
                        sx={(theme) => ({ color: theme.palette.secondary.main })}
                        className="me-2"></CreateIcon>
                      Edit Menu
                    </AppButton>
                  </Grid>
                </Grid>
              </Grid>
            </Header>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg="12">
            <MenuNavBar
              activeTab={linkCategory?.item}
              subCategory={menuSubCategory?.[0]?.name}></MenuNavBar>
          </Col>
        </Row>
        {(linkCategory?.item === menuText.DINNER ||
          linkCategory?.item === menuText.LUNCH ||
          linkCategory?.item === menuText.BRUNCH) && (
          <Row className="mt-4">
            <Col lg="12">
              <SubMenuBar activeTab={linkCategory?.category} menu={menuSubCategory} />
            </Col>
          </Row>
        )}
        {linkCategory?.item === menuText.SPIRITS && (
          <Row className="mt-4">
            <Col lg={12}>
              <SpiritsSubMenuBar isCMS={true} activeTab={menuCategory?.name} />
            </Col>
          </Row>
        )}
        <Row className="mt-4">
          <Col lg="4">
            <SearchTextField
              id="search"
              name="search"
              value={searchValue}
              onChange={handleSearchField}
              fullWidth
              type="search"
              placeholder="Search menu items or ingredients"
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      backgroundColor: theme.palette.primary.main
                    }}>
                    <SearchIcon htmlColor={`${theme.palette.text.secondary}`} />
                  </InputAdornment>
                )
              }}
              style={{
                border: `1px solid ${theme.palette.text.secondary}`,
                backgroundColor: theme.palette.primary.main,
                borderRadius: 6
              }}
            />
          </Col>
        </Row>
      </Container>
      {(linkCategory?.item === menuText.DINNER ||
        linkCategory?.item === menuText.LUNCH ||
        linkCategory?.item === menuText.BRUNCH) && (
        <Container fluid className="p-0 mt-4">
          <div className={classes.menuContainer}>
            {menuItems?.length > 0 ? (
              menuItems?.map((menuItem, idx) => {
                return <MenuListContainer data={menuItem} key={idx}></MenuListContainer>
              })
            ) : (
              <Typography>
                {searchValue ? "No items found matching your search" : "No Data Found"}
              </Typography>
            )}
          </div>
        </Container>
      )}
      {linkCategory?.item === menuText.BARMENU && (
        <Container fluid className="p-0 mt-4">
          <div className={classes.menuContainer}>
            {barItems?.length > 0 ? (
              barItems?.map((menuItem, idx) => {
                return <BarListItems data={menuItem} key={idx}></BarListItems>
              })
            ) : (
              <Typography>
                {searchValue ? "No items found matching your search" : "No Data Found"}
              </Typography>
            )}
          </div>
        </Container>
      )}
      {linkCategory?.item === menuText.WINEBAR && (
        <Container fluid className="p-0 mt-4">
          <div className={classes.menuContainer}>
            {wineItems?.length > 0 ? (
              wineItems?.map((menuItem, idx) => {
                return <WineListItems data={menuItem} key={idx}></WineListItems>
              })
            ) : (
              <Typography>
                {searchValue ? "No items found matching your search" : "No Data Found"}
              </Typography>
            )}
          </div>
        </Container>
      )}
      {linkCategory?.item === menuText.SPIRITS && (
        <>
          <Grid item sx={{ maxWidth: "50%" }}>
            {SpiritSubMenu?.map(
              (foodList, i) =>
                foodList?.id === menuCategory?.name &&
                (foodList?.description ||
                  foodList?.image_url ||
                  foodList?.unique_facts?.length > 0) && (
                  <DMV_SpiritSummary key={i} isEdit={false} foodList={foodList} isCMS={true} />
                )
            )}
          </Grid>
          <Container fluid className="p-0 mt-4">
            <div className={classes.spiritContainer}>
              {spiritItems?.length > 0 ? (
                spiritItems?.map((menuItem, idx) => {
                  return (
                    <SpiritsMenuListContainer data={menuItem} key={idx}></SpiritsMenuListContainer>
                  )
                })
              ) : (
                <Typography>
                  {searchValue ? "No items found matching your search" : "No Data Found"}
                </Typography>
              )}
            </div>
          </Container>
        </>
      )}
    </Fragment>
  )
}

export default Menu
