import { Grid, List, Typography, useTheme } from "@mui/material"
import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { getGamificationDataAction } from "redux/superAdmin/actions"

const customNames = {
  PROFILE_PICTURE: "Upload Profile Photo",
  FEATURED_ITEM: "Featured Item of the Month",
  TASTING_NOTES: "Tasting Notes Submission",
  FLASH_CARDS: "Flash Cards",
  TRIVIA_AWARD: "Weekly Trivia Award",
  EVENT_RSVP: "Event RSVP",
  REVIEW: "5-Star Review",
  POSITIVE_REVIEW: "5-Star Review with Positive Verbatim"
}

const Gamification = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const { gamificationData } = useSelector((store) => store.superAdmin)
  const [record, setRecord] = useState([])
  const styles = {
    list: {
      display: "flex",
      flexDirection: "column",
      marginTop: "14px",
      marginBottom: "18px"
    },
    label: {
      color: theme.palette.text.secondary,
      marginBottom: "4px"
    },
    userImage: {
      width: "120px",
      height: "120px",
      objectFit: "cover",
      borderRadius: "50%"
    }
  }

  const handleEditGamification = () => {
    history.push("/super-admin/dashboard/gamification/edit-gamification")
  }

  useEffect(() => {
    const updatedData = []
    for (const key of Object.keys(customNames)) {
      const item = gamificationData.find((obj) => obj.name === key)
      let occurrence = null
      if (key === "FEATURED_ITEM" || key === "TRIVIA_AWARD") {
        occurrence = "Week"
      } else if (key === "FLASH_CARDS") {
        occurrence = "Day"
      }
      if (item) {
        updatedData.push({
          id: item.id,
          name: customNames[key],
          coins: item.coins,
          occurrence: occurrence
        })
      }
    }
    setRecord(updatedData)
  }, [gamificationData])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Gamification"
    dispatch(getGamificationDataAction())
  }, [])

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Header title="Gamification" breadCrumbs={breadCrumbs}>
            <AppButton
              onClick={() => handleEditGamification()}
              sx={{ padding: "13px 36px", minWidth: "200px" }}>
              Edit
            </AppButton>
          </Header>
        </Grid>
      </Grid>
      <Container fluid key="">
        <Row>
          {record?.map((item) => (
            <Col lg="4" className="ps-0" key={item?.is}>
              <List sx={styles.list}>
                <Typography sx={styles.label} variant="body1Regular">
                  {item?.name}
                </Typography>
                <Typography variant="body1Regular">
                  {item.coins || "N/A"} {item.coins === 1 ? "Coin" : "Coins"}{" "}
                  {item.occurrence && `/${item.occurrence}`}
                </Typography>
              </List>
            </Col>
          ))}
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Gamification
