import React, { useState } from "react"
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material"
import AppTextField from "components/StyledComponents/AppTextField"
import CustomDivider from "components/StyledComponents/Divider"
import { useTheme } from "@mui/system"
import AppButton from "components/StyledComponents/AppButton"
import ImageUploadField from "components/FileUpload/ImageUploadField"
import { Col } from "react-bootstrap"
import AppDatePicker from "components/AppDatePicker"
import {
  uploadPDFThumbnailAction,
  uploadTastingNotesImagesAction,
  WineMonthVideoThumbnailAction
} from "redux/marketing/actions"
import { useDispatch, useSelector } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import CloseIcon from "@mui/icons-material/Close"
import { FieldArray } from "formik"
import ImageCropper from "components/ImageCropper"
import moment from "moment"
import AppAutocomplete from "components/AppAutocomplete"
import { useRef } from "react"
import StyledLabel from "components/StyledComponents/StyledLabel"
import { DMVActions } from "redux/DMV/slice/user"

const indexFoodItem = ["First", "Second", "Third", "Fourth", "Fifth"]

function ImportTab({ formik, isEdit = false }) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [openCrop, setCrop] = useState(false)
  const [fileDetails, setFileDetails] = useState(null)
  const [imageObjectURL, setImageObjectUrl] = useState(null)
  const [indexValue, setIndex] = useState(null)
  const tastingNoteUid = useRef(0)
  const [uploadType, setUploadType] = useState("")
  const { alcoholType } = useSelector((state) => state?.dmv || {})

  const styles = {
    container: {
      marginTop: "5px"
    },
    uploadBtnContainer: {
      padding: "12px 79px"
    },
    mainDivider: {
      backgroundColor: theme.palette.background.main,
      opacity: 1,
      marginTop: "30px"
    },
    submitContainer: {
      marginTop: "20px"
    },
    closeCon: {
      display: "flex",
      alignItems: "center",
      marginTop: "25px"
    },
    delete: {
      borderRadius: "50%",
      background: theme.palette.error.main,
      padding: "6px"
    }
  }

  const onImageUpload = (image) => {
    setUploadType("")
    if (image) {
      setUploadType("videoThumbnail")
      setCrop(true)
      setFileDetails(image)
      setImageObjectUrl(URL.createObjectURL(image))
    }
  }

  const handleImageDelete = (formik) => {
    dispatch(marketingActions.setWineMonthVideoThumbnail(null))
    formik.setFieldValue("videoThumbnail", null)
  }

  const onImageUploadPDF = (image) => {
    setUploadType("")
    if (image) {
      setUploadType("wineImageVideo")
      setCrop(true)
      setFileDetails(image)
      setImageObjectUrl(URL.createObjectURL(image))
    }
  }

  const handleImageDeletePDF = (formik) => {
    dispatch(marketingActions.setWineMonthPDFThumbnail(null))
    formik.setFieldValue("wineImageVideo", null)
  }

  const onImageUploadItem = (image, index) => {
    setUploadType("")
    if (image) {
      setUploadType("image")
      setCrop(true)
      setFileDetails(image)
      setIndex(index)
      setImageObjectUrl(URL.createObjectURL(image))
    }
  }

  const handleImageDeleteItem = (formik, index) => {
    dispatch(marketingActions.setTastingNotesImages({ index, id: null }))
    formik.setFieldValue(`menuPairing[${index}].image`, null)
  }

  const deleteWineCharacteristics = (index, arrayHelper) => {
    arrayHelper.remove(index)
  }

  function handleCroppedImage(image) {
    if (uploadType === "videoThumbnail") {
      let data = new FormData()
      data.append("media", image)
      data.append("media_type", "IMAGE")
      data.append("content_type", image.type)
      dispatch(WineMonthVideoThumbnailAction(data))
      formik.setFieldValue("videoThumbnail", image)
      setUploadType("")
    } else if (uploadType === "wineImageVideo") {
      let data = new FormData()
      data.append("media", image)
      data.append("media_type", "IMAGE")
      data.append("content_type", image.type)
      dispatch(uploadPDFThumbnailAction(data))
      formik.setFieldValue("wineImageVideo", image)
      setUploadType("")
    } else {
      let data = new FormData()
      data.append("media", image)
      data.append("media_type", "IMAGE")
      data.append("content_type", image.type)
      dispatch(uploadTastingNotesImagesAction({ indexValue, data }))
      formik.setFieldValue(`menuPairing[${indexValue}].image`, image)
      setUploadType("")
    }
  }

  function showCropperModal(imageObjectURL, fileDetails) {
    return (
      <ImageCropper
        setSelectedImage={(image) => handleCroppedImage(image)}
        srcImage={imageObjectURL}
        fileDetail={fileDetails}
        aspectRatio={uploadType === "videoThumbnail" ? 16 / 9 : 0}
        onClose={setCrop}
        openCrop={openCrop}></ImageCropper>
    )
  }

  const handleChange = (event) => {
    dispatch(DMVActions.setAlcoholType(event.target.value))
  }

  return (
    <>
      <Grid container spacing={4} sx={styles.container}>
        {showCropperModal(imageObjectURL, fileDetails)}
        <Grid item sm={12} md={6} lg={4} className="datesContainer">
          <StyledLabel variant="body1Regular">
            {"Select Type"}
            <span>*</span>
          </StyledLabel>
          <Box className="dateFieldContainer w-100 mt-2">
            <FormControl variant="filled">
              <RadioGroup
                className="d-flex flex-row px-4"
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={alcoholType}
                onChange={(e) => {
                  handleChange(e)
                }}>
                <FormControlLabel
                  value="wine"
                  control={<Radio color="secondary" />}
                  label="Wine"
                  sx={{ marginRight: "48px" }}
                />
                <FormControlLabel
                  value="spirit"
                  control={<Radio color="secondary" />}
                  label="Spirit"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
        <Grid item sm={12} md={6} lg={4} sx={alcoholType === "" && { opacity: "0.3" }}>
          <Box>
            <Typography variant="body1Regular" sx={styles.heading}>
              Date
            </Typography>
            <span style={{ color: "#CC4B37" }}>*</span>
          </Box>
          <Box sx={{ display: "flex", marginTop: "5px" }} className="datesContainer">
            <Col lg="6" className="me-2 dateFieldContainer">
              <AppDatePicker
                views={["year", "month", "day"]}
                disabled={alcoholType === "" ? true : false}
                inputFormat="dd MMM yyyy"
                key={"month"}
                minDate={moment().subtract(5, "y").toDate()}
                maxDate={moment().add(5, "y").toDate()}
                value={formik.values.month}
                onChange={(val) => formik.setFieldValue("month", val)}
                error={formik.errors.month}
                touched={formik.touched.month}
              />
            </Col>
          </Box>
        </Grid>

        <Grid item sm={12} md={6} lg={4} sx={alcoholType === "" && { opacity: "0.3" }}>
          <AppTextField
            id="nameOfTheWine"
            name="nameOfTheWine"
            type="text"
            onBlur={formik.handleBlur}
            fullWidth
            isUpperCase={true}
            label="Name of the Feature Item"
            isRequired={true}
            placeholder="Enter Name of the Feature Item"
            isToolTip={true}
            renderToolTip="Format : Name of Featured Item of the Month (Month, Year) <br/>  Example : CERDON DE BUGEY-RENARDAT-FACHE ‘ANCESTRAL ROSE’ 2017"
            value={formik.values.nameOfTheWine}
            onChange={formik.handleChange}
            error={
              formik.touched.nameOfTheWine &&
              formik.errors.nameOfTheWine &&
              Boolean(formik.errors.nameOfTheWine)
            }
            helperText={formik.touched.nameOfTheWine && formik.errors.nameOfTheWine}
            disabled={alcoholType === "" ? true : false}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} sx={alcoholType === "" && { opacity: "0.3" }}>
          <ImageUploadField
            label="Upload Video Thumbnail"
            isRequired={true}
            isVariant={true}
            placeholder="Upload Image"
            value={formik.values.videoThumbnail}
            onChange={(e) => onImageUpload(e)}
            onDelete={() => handleImageDelete(formik)}
            error={formik.errors.videoThumbnail}
            touched={formik.touched.videoThumbnail}
            disabled={alcoholType === "" ? true : false}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} sx={alcoholType === "" && { opacity: "0.3" }}>
          <ImageUploadField
            label="Upload Bottle Image"
            isRequired={true}
            isVariant={true}
            placeholder="Wine Image"
            value={formik.values.wineImageVideo}
            onChange={(e) => onImageUploadPDF(e)}
            onDelete={() => handleImageDeletePDF(formik)}
            error={formik.errors.wineImageVideo}
            touched={formik.touched.wineImageVideo}
            disabled={alcoholType === "" ? true : false}
            isToolTip={true}
            renderToolTip="Ensure bottle image has a transparent background and is perfectly/straight aligned."
          />
          <Typography variant="caption4" sx={{ opacity: 0.6 }}>
            ( Please select a PNG format Image )
          </Typography>
        </Grid>

        <Grid item lg={6} md={6} sm={8} sx={alcoholType === "" && { opacity: "0.3" }}>
          <AppTextField
            id="videoURL"
            name="videoURL"
            type="text"
            isUpperCase={true}
            onBlur={formik.handleBlur}
            label="Video URL"
            isRequired={true}
            placeholder="Enter the video URL"
            value={formik.values.videoURL}
            onChange={formik.handleChange}
            error={
              formik.touched.videoURL && formik.errors.videoURL && Boolean(formik.errors.videoURL)
            }
            helperText={formik.touched.videoURL && formik.errors.videoURL}
            formStyle={{ width: "100%" }}
            disabled={alcoholType === "" ? true : false}
          />
        </Grid>

        {/* <Grid item sm={12} sx={alcoholType === "" && { opacity: "0.3" }}>
          <Typography variant="h2" sx={styles.heading}>
            Tasting Notes
          </Typography>
        </Grid> */}
        <Grid item lg={6} md={6} sm={8} sx={alcoholType === "" && { opacity: "0.3" }}>
          <AppTextField
            id="wineRegion"
            name="wineRegion"
            type="text"
            onBlur={formik.handleBlur}
            isUpperCase={true}
            label="Region"
            placeholder="Enter the Region"
            value={formik.values.wineRegion}
            onChange={formik.handleChange}
            error={
              formik.touched.wineRegion &&
              formik.errors.wineRegion &&
              Boolean(formik.errors.wineRegion)
            }
            helperText={formik.touched.wineRegion && formik.errors.wineRegion}
            formStyle={{ width: "100%" }}
            disabled={alcoholType === "" ? true : false}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={8} sx={alcoholType === "" && { opacity: "0.3" }}>
          <AppTextField
            id="grapeVarietals"
            name="grapeVarietals"
            type="text"
            isUpperCase={true}
            label={alcoholType === "spirit" ? "Ingredients" : "Grape Varietals/Ingredients"}
            onBlur={formik.handleBlur}
            placeholder={
              alcoholType === "spirit"
                ? "Enter Ingredients"
                : "Enter the Grape Varietals/Ingredients"
            }
            value={formik.values.grapeVarietals}
            onChange={formik.handleChange}
            error={
              formik.touched.grapeVarietals &&
              formik.errors.grapeVarietals &&
              Boolean(formik.errors.grapeVarietals)
            }
            helperText={formik.touched.grapeVarietals && formik.errors.grapeVarietals}
            formStyle={{ width: "100%" }}
            disabled={alcoholType === "" ? true : false}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={8} sx={alcoholType === "" && { opacity: "0.3" }}>
          <AppTextField
            id="description"
            name="description"
            onBlur={formik.handleBlur}
            type="text"
            isUpperCase={true}
            label="Description"
            isRequired={true}
            placeholder="Enter the Description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description &&
              formik.errors.description &&
              Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            formStyle={{ width: "100%" }}
            disabled={alcoholType === "" ? true : false}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} sx={alcoholType === "" && { opacity: "0.3" }}>
          <AppAutocomplete
            id="tastingNotes"
            isRequired={true}
            placeholder="Enter Tasting Notes"
            label={"Tasting Notes"}
            isUpperCase={true}
            value={formik.values.tastingNotes}
            error={formik.touched.tastingNotes && Boolean(formik.errors.tastingNotes)}
            helperText={formik.touched.tastingNotes && formik.errors.tastingNotes}
            onChange={(event, newValue) => formik.setFieldValue("tastingNotes", newValue)}
            isDisabled={alcoholType === "" ? true : false}
          />
        </Grid>

        <Grid
          item
          sm={12}
          className="d-flex align-items-end"
          sx={alcoholType === "" && { opacity: "0.3" }}>
          <Typography variant="h2" sx={styles.heading}>
            {alcoholType === "wine" ? "Menu Pairing" : "Classic Cocktails"}
          </Typography>
          <Typography variant="caption4" className="ms-2" sx={{ opacity: 0.6 }}>
            {`( You can add upto 5 ${
              alcoholType === "wine" ? "Menu Pairing" : "Classic Cocktails"
            } items )`}
          </Typography>
        </Grid>
        <Grid item sm={12} sx={alcoholType === "" && { opacity: "0.3" }}>
          <FieldArray
            name={"menuPairing"}
            render={(arrayHelper) => (
              <>
                {formik.values.menuPairing?.map((category, index) => (
                  <Grid
                    container
                    className={`${index > 0 ? "mt-2" : null}`}
                    spacing={4}
                    key={`menuPairing${category?.uid}`}>
                    <Grid item lg={5} md={5} sm={10}>
                      <AppTextField
                        label={
                          alcoholType === "wine"
                            ? `Food Items Name ${indexFoodItem[index]}`
                            : `Classic Cocktails ${indexFoodItem[index]}`
                        }
                        fullWidth
                        isUpperCase={true}
                        placeholder={
                          alcoholType === "wine"
                            ? `Enter Food Item Name ${indexFoodItem[index]}`
                            : `Enter Classic Cocktails ${indexFoodItem[index]}`
                        }
                        value={formik.values?.menuPairing[index].title}
                        onChange={formik.handleChange(`menuPairing[${index}].title`)}
                        error={
                          formik.touched.menuPairing?.[index]?.title &&
                          formik.errors.menuPairing?.[index]?.title &&
                          Boolean(formik.errors.menuPairing?.[index]?.title)
                        }
                        helperText={
                          formik.touched.menuPairing?.[index]?.title &&
                          formik.errors.menuPairing?.[index]?.title
                        }
                        disabled={alcoholType === "" ? true : false}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={10}>
                      <ImageUploadField
                        label="Upload Item Image"
                        isVariant={true}
                        placeholder="Item Image"
                        value={formik.values.menuPairing[index]?.image}
                        onChange={(e) => onImageUploadItem(e, index)}
                        onDelete={() => handleImageDeleteItem(formik, index)}
                        error={formik.errors?.menuPairing?.[index]?.image}
                        touched={formik.touched.menuPairing?.[index]?.image}
                        disabled={alcoholType === "" ? true : false}
                      />
                    </Grid>
                    {formik.values?.menuPairing?.length > 1 ? (
                      <Grid item sm={1} sx={styles.closeCon}>
                        <CloseIcon
                          onClick={() => deleteWineCharacteristics(index, arrayHelper)}
                          sx={styles.delete}></CloseIcon>
                      </Grid>
                    ) : null}
                  </Grid>
                ))}
                {formik.values?.menuPairing?.length < 5 ? (
                  <Grid item sm={12} className="mt-4">
                    <AppButton
                      onClick={() =>
                        arrayHelper.push({
                          uid: ++tastingNoteUid.current,
                          title: "",
                          description: ""
                        })
                      }
                      className={styles.uploadBtn}
                      disabled={alcoholType === "" ? true : false}>
                      Add More
                    </AppButton>
                  </Grid>
                ) : null}
              </>
            )}></FieldArray>
        </Grid>
        <Grid item lg={6} md={6} sm={8} sx={alcoholType === "" && { opacity: "0.3" }}>
          <Grid item className="datesContainer">
            <StyledLabel variant="body1Regular">
              {"Schedule Notification Date"}
              <span>*</span>
            </StyledLabel>
            <Box className="dateFieldContainer w-100 mt-2">
              <AppDatePicker
                minDate={new Date()}
                maxDate={new Date().setMonth(new Date().getMonth() + 5)}
                inputFormat="dd MMM yyyy"
                key={"scheduleNotificationDate"}
                value={formik.values.scheduleNotificationDate}
                onChange={(val) => formik.setFieldValue("scheduleNotificationDate", val)}
                error={formik.errors.scheduleNotificationDate}
                touched={formik.touched.scheduleNotificationDate}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={8} sx={alcoholType === "" && { opacity: "0.3" }}>
          <AppTextField
            type="time"
            fullWidth
            label="Schedule Notification Time"
            isRequired={true}
            isUpperCase={true}
            value={formik.values.scheduleNotificationTime}
            onChange={formik.handleChange("scheduleNotificationTime")}
            error={
              formik.touched.scheduleNotificationTime &&
              Boolean(formik.errors.scheduleNotificationTime)
            }
            helperText={
              formik.touched.scheduleNotificationTime && formik.errors.scheduleNotificationTime
            }
          />
        </Grid>
      </Grid>
      <CustomDivider sx={styles.mainDivider} />
      {formik.values.videoURL === "" && formik.values.wineImageVideo === "" ? (
        <></>
      ) : (
        <Grid
          item
          sm={12}
          className="d-flex justify-content-center align-items-center"
          style={styles.submitContainer}>
          <AppButton onClick={formik.handleSubmit} className={styles.uploadBtn}>
            {isEdit ? "Update" : "Save"}
          </AppButton>
        </Grid>
      )}
    </>
  )
}

export default ImportTab
