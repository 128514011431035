import { Box, Grid, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import ContestTable from "./components/ContestTable"
import PendingQuestionTable from "./components/PendingQuestionTable"
import Header from "components/Header/Header"
import { useTheme } from "@mui/system"
import AppButton from "components/StyledComponents/AppButton"
import { useHistory } from "react-router-dom"
import { createBreadcrumbs } from "../../../../helpers/breadCrumbs"
import { useDispatch } from "react-redux"
import { learningDevelopmentActions } from "redux/L&D/slice/user"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import { deleteMultipleTriviaContestAction } from "redux/L&D/actions"
import IncorrectContest from "./components/ContestTable/IncorrectContest"

const TriviaContest = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)
  const history = useHistory()
  const [selectedContestCount, setSelectedContestCount] = useState(0)
  const [selectedContests, setSelectedContests] = useState([])
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [isAllSelected, setIsAllSelected] = useState(false)
  const [selectedIncorrectContestCount, setSelectedIncorrectContestCount] = useState(0)
  const [selectedIncorrectContests, setSelectedIncorrectContests] = useState([])
  const [isAllSelectedIncorrect, setIsAllSelectedIncorrect] = useState(false)

  const styles = {
    heading: {
      color: theme.palette.text.primary
    },
    btnAddContest: {
      paddingLeft: "30px",
      paddingRight: "30px",
      marginRight: "16px"
    },
    btnDelete: {
      color: theme.palette.red.main,
      borderColor: theme.palette.red.main,
      paddingLeft: "30px",
      paddingRight: "30px",
      marginRight: "16px",
      "&:hover": {
        borderColor: theme.palette.red.main,
        backgroundColor: theme.palette.background.default
      }
    },
    btnQuestion: {
      paddingLeft: "30px",
      paddingRight: "30px",
      marginRight: "16px"
    },
    btnAddTrivia: {
      paddingLeft: "30px",
      paddingRight: "30px"
    }
  }

  const toggleDeletePopup = () => {
    setIsDeletePopupOpen((prev) => !prev)
  }

  function removeElements(data) {
    return data !== false && data !== "disabled"
  }

  const deleteAllContest = () => {
    const payload = selectedContests.filter(removeElements)
    const incorrectData = selectedIncorrectContests.filter(removeElements)
    payload?.length && dispatch(deleteMultipleTriviaContestAction(payload))
    incorrectData?.length && dispatch(deleteMultipleTriviaContestAction(incorrectData))
    setSelectedContestCount(0)
    setSelectedIncorrectContestCount(0)
    setSelectedContests(
      selectedContests.map((contest) => (contest === "disabled" ? contest : false))
    )
    setSelectedIncorrectContests(
      selectedContests.map((contest) => (contest === "disabled" ? contest : false))
    )
    setIsAllSelected(false)
    setIsAllSelectedIncorrect(false)
    setIsDeletePopupOpen((prev) => !prev)
  }

  useEffect(() => {
    document.title = "Trivia Contest"
    dispatch(learningDevelopmentActions.setUploadQuestionsData([]))
    setBreadcrumbs(createBreadcrumbs())
  }, [])

  const handleTriviaQuestionBank = () => {
    history.push("/learning_development/dashboard/question-bank")
  }

  return (
    <Grid container>
      <DeleteConfirmationDialog
        open={isDeletePopupOpen}
        onClose={() => setIsDeletePopupOpen(false)}
        onDelete={deleteAllContest}
        description={`Are you sure to delete ${
          selectedContestCount > 1 || selectedIncorrectContestCount > 1 ? `all` : ""
        } The Selected ${
          selectedContestCount > 1 || selectedIncorrectContestCount > 1 ? `contests` : `contest`
        } ?`}
      />
      <Grid item xs={12} spacing={1}>
        <Header title="Trivia Contest & Questions" breadCrumbs={breadcrumbs}>
          {selectedContestCount || selectedIncorrectContestCount ? (
            <AppButton styles={styles.btnDelete} onClick={toggleDeletePopup}>
              Delete
            </AppButton>
          ) : null}
          <AppButton onClick={() => handleTriviaQuestionBank()} styles={styles.btnQuestion}>
            Trivia Question Bank
          </AppButton>
        </Header>
      </Grid>
      <Grid
        container
        direction={history?.location?.state?.isPendingApproval ? "column-reverse" : "column"}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={styles.heading} variant="h2">
              Trivia Contest
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ContestTable
              {...{
                setIsAllSelected,
                isAllSelected,
                selectedContestCount,
                setSelectedContestCount,
                selectedContests,
                setSelectedContests
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={styles.heading} variant="h2">
              Incorrect Trivia Contest
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <IncorrectContest
              {...{
                isAllSelectedIncorrect,
                setIsAllSelectedIncorrect,
                selectedIncorrectContestCount,
                setSelectedIncorrectContestCount,
                selectedIncorrectContests,
                setSelectedIncorrectContests
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Box height={35} />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={styles.heading} variant="h2">
              Pending Questions
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PendingQuestionTable />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TriviaContest
