import React, { useState } from "react"
import avatar from "assets/images/user/avatarUser.svg"
import styles from "./adminUserList.module.scss"

function ProfileDisplayer({ image }) {
  const [isLoading, setIsLoading] = useState(true)

  return (
    <>
      {isLoading && (
        <img
          src={avatar}
          alt="Fallback Avatar"
          style={{
            borderRadius: "50%",
            width: "50px",
            height: "50px",
            objectFit: "cover",
            display: isLoading ? "block" : "none"
          }}
        />
      )}

      <div className={styles.tableAvatar__img}>
        <img
          src={image}
          onLoad={() => setIsLoading(false)}
          alt="Dynamic"
          style={{
            display: isLoading ? "none" : "block"
          }}
        />
      </div>
    </>
  )
}

export default ProfileDisplayer
