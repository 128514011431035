import { Grid, styled, TextareaAutosize, Typography } from "@mui/material"
import React from "react"

const StyledTextArea = styled(TextareaAutosize)(({ theme }) => ({
  backgroundColor: "transparent",
  border: `1px solid ${theme.palette.text.secondary}`,
  borderRadius: 6,
  minHeight: 24,
  height: 24,
  boxSizing: "border-box",
  color: theme.palette.text.primary,
  padding: "12px 16px",
  ...theme.typography.body1Regular,
  "&:focus-visible": {
    outline: "none"
  },
  "&.error": {
    border: `1px solid ${theme.palette.error.main}`
  }
}))

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: 8,
  "& span": {
    color: theme.palette.error.main
  }
}))

const AppTextArea = ({
  width = "100%",
  label = "",
  onChange,
  style = {},
  className = "",
  isRequired,
  error,
  minRows = 1,
  touched,
  charLimit,
  value,
  onBlur,
  helperText,
  ...props
}) => {
  return (
    <Grid container style={style} className={className}>
      {label ? (
        <StyledLabel variant="body1Regular">
          {label}
          {isRequired ? <span>*</span> : null}
        </StyledLabel>
      ) : null}
      <Grid item xs={12}>
        <StyledTextArea
          onChange={onChange}
          onBlur={onBlur}
          className={error && touched ? "error" : ""}
          style={{ width }}
          value={value}
          minRows={minRows}
          {...props}
          onInput={(event) => {
            if (charLimit && event.target.value?.length > charLimit) {
              event.target.value = event.target.value.slice(0, charLimit)
            }
          }}
        />
      </Grid>
      <Grid container>
        <Grid item xs={10}>
          {error && touched && (
            <div style={{ color: "#A9402F", fontSize: "0.75rem", marginTop: 3 }}>
              {helperText ?? error}
            </div>
          )}
        </Grid>
        <Grid item xs={2} style={{ textAlign: "right" }}>
          {charLimit && (
            <div style={{ color: "#ad916f", fontSize: "0.75rem", marginTop: 3 }}>
              {value?.length || 0}/{charLimit}
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AppTextArea
