import axios from "axios"
import { toast } from "react-toastify"
import { call, put, select } from "redux-saga/effects"
import slice from "../slice/user"
import app from "../../app/slice/app"
import user from "redux/user/user"
import { appActions } from "redux/app/slice/app"

const baseUrl = process.env.REACT_APP_API_MY_URL

export function* login(action) {
  const isUpdating = yield select((store) => store.app?.isUpdating)
  if (!isUpdating) {
    yield put(appActions.setIsUpdating(true))
    yield put(appActions.setIsLoading(true))
    try {
      const { data } = yield call(axios.post, `${baseUrl}/user/login/`, action.payload)
      const tokens = data.data.tokens
      const userId = data?.data?.id ?? ""
      if (tokens) {
        localStorage.setItem("access_token", tokens.access)
        localStorage.setItem("refresh_token", tokens.refresh)
      }
      yield put(app.actions.setIsMultiRestaurant(data?.data?.multi_restaurant))
      yield put(user.actions.setUserRestaurant(data?.data?.restaurant_data))
      const { data: userData } = yield call(axios.get, `${baseUrl}/user/${userId}/`)
      yield put(user.actions.setUserData(data.data))
      yield put(user.actions.setUserWithData(userData))
      yield put(app.actions.setIsLoggedInData(true))
    } catch (e) {
      console.log(e)
    }
    yield put(appActions.setIsLoading(false))
    yield put(appActions.setIsUpdating(false))
  }
}

export function* getRestaurants(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/user/restaurants/`, action.payload)
    if (data.success) {
      yield put(user.actions.setUserRestaurantList(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getTriviaContestList(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/trivia/?page=${action.payload?.page}`)
    if (data.success) {
      yield put(
        slice.actions.setTriviaList({
          totalPages: data?.data?.pages,
          data: data.data.results
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getIncorrectTriviaContestList(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/trivia/?trivia_type=INCORRECT&page=${action.payload?.page}`
    )
    if (data.success) {
      yield put(
        slice.actions.setIncorrectTriviaList({
          totalPages: data?.data?.pages,
          data: data.data.results
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteTriviaContestItem(action) {
  try {
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/trivia/${action.payload}/`)

    const { data: pending } = yield call(
      axios.get,
      `${baseUrl}/trivia/pending-questions/`,
      action.payload
    )
    if (pending.success) {
      let pendingQuestions = []
      pending?.data?.forEach((contest) => {
        contest?.questions.forEach((question) => {
          pendingQuestions.push({ ...question, trivia: contest?.trivia })
        })
      })
      yield put(slice.actions.setPendingQuestions(pendingQuestions))
    }

    const { data } = yield call(axios.get, `${baseUrl}/trivia/`)
    if (data.success) {
      yield put(
        slice.actions.setTriviaList({
          totalPages: data?.data?.pages,
          data: data.data.results
        })
      )
    }
    const { data: incorrectTrivia } = yield call(
      axios.get,
      `${baseUrl}/trivia/?trivia_type=INCORRECT`
    )
    if (incorrectTrivia.success) {
      yield put(
        slice.actions.setIncorrectTriviaList({
          totalPages: incorrectTrivia?.data?.pages,
          data: incorrectTrivia.data.results
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* createTrivia(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/trivia/`, action.payload)
    if (data.success || data.code == 400) {
      yield put(slice.actions.setTriviaId(data.data.id))
      yield put(slice.actions.setQuestionsPublished(false))
      yield put(slice.actions.setIncorrectTriviaDuration({}))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addTriviaQuestions(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.patch, `${baseUrl}/trivia/${action.payload.triviaId}/`, {
      status: action.payload.status
    })
    if (data.success) {
      const { data } = yield call(
        axios.patch,
        `${baseUrl}/trivia/${action.payload.triviaId}/questions/`,
        action.payload.data
      )
      if (data.success) {
        yield put(slice.actions.setQuestionsPublished(true))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getTriviaCategories(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/trivia/categories/?web=true`, action.payload)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setTriviaCategories(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getPendingQuestions(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/trivia/pending-questions/`, action.payload)
    if (data.success) {
      let pendingQuestions = []
      data?.data?.forEach((contest) => {
        contest?.questions.forEach((question) => {
          pendingQuestions.push({ ...question, trivia: contest?.trivia })
        })
      })
      yield put(slice.actions.setPendingQuestions(pendingQuestions))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* uploadQuestionImage(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload.data)
    if (data.success) {
      yield put(
        slice.actions.setUploadedImages({
          index: action.payload.index,
          data: {
            id: data.data.id,
            url: URL.createObjectURL(action?.payload?.data?.get("media"))
          }
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* approveQuestion(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/trivia/questions/${action.payload.questionId}/`,
      { status: "Approved" }
    )
    if (data.success) {
      const { data } = yield call(axios.get, `${baseUrl}/trivia/pending-questions/`)
      if (data.success) {
        let pendingQuestions = []
        data?.data?.forEach((contest) => {
          contest?.questions.forEach((question) => {
            pendingQuestions.push({ ...question, trivia: contest?.trivia })
          })
        })
        yield put(slice.actions.setPendingQuestions(pendingQuestions))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getTriviaCorrectAnswers(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/trivia/trivia-analytics/correct-answers/?month=${action.payload}`
    )
    if (data.success) {
      yield put(
        slice.actions.setTriviaCorrectAnswers(
          data?.data?.sort(
            (a, b) => parseFloat(b.percentage_correct) - parseFloat(a.percentage_correct)
          ) || []
        )
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getTriviaIncorrectAnswers(action) {
  const { date, restaurant } = action.payload
  let params = `?month=${date}`
  if (restaurant && restaurant !== "All") {
    params += `&restaurant=${restaurant}`
  }

  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/trivia/trivia-analytics/incorrect-answers/${params}`
    )
    if (data.success) {
      yield put(
        slice.actions.setTriviaIncorrectAnswers(
          data?.data?.sort(
            (a, b) => parseFloat(b.percentage_incorrect) - parseFloat(a.percentage_incorrect)
          ) || []
        )
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* editSuggestedQuestion(action) {
  try {
    const { isRankUpdate } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/trivia/questions/${action.payload.questionId}/`,
      action.payload.data
    )
    if (data.success) {
      yield put(slice.actions.setSuggestedQuestionEdited(true))
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/trivia/question-bank/?page=1&page_size=9999${
          action?.payload?.platform !== "ALL" && action?.payload?.platform
            ? `&platform=${action?.payload?.platform}`
            : ""
        }${
          action.payload?.type
            ? `&type=INCORRECT`
            : action.payload?.question_used !== "ALL" && action.payload?.question_used
            ? `&question_used=${action.payload?.question_used}`
            : ""
        }`
      )
      if (newData.success) {
        yield put(slice.actions.setQuestionBankData(newData?.data))
        toast.success(
          isRankUpdate ? "Question's rank updated successfully" : "Question updated successfully",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "dark"
          }
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* uploadSuggestedQuestionImage(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload)
    if (data.success) {
      yield put(
        slice.actions.setSuggestedQuestionImage({
          id: data.data.id,
          url: URL.createObjectURL(action?.payload?.get("media"))
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getViewTriviaContestDetails() {
  try {
    yield put(appActions.setIsLoading(true))
    const triviaId = yield select((store) => store.learningDevelopment.trivia.viewTrivia.triviaId)
    const { data } = yield call(axios.get, `${baseUrl}/trivia/${triviaId}/`)
    if (data.success) {
      yield put(slice.actions.setViewTriviaData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getViewTriviaQuestions() {
  try {
    yield put(appActions.setIsLoading(true))
    const triviaId = yield select((store) => store.learningDevelopment.trivia.viewTrivia.triviaId)
    const { data } = yield call(axios.get, `${baseUrl}/trivia/${triviaId}/questions/`)
    if (data.success) {
      yield put(slice.actions.setViewTriviaQuestions(data.data?.question ?? []))
      yield put(slice.actions.setTriviaQuestionsCount(data.data?.category_count))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* setQuestionStatusViewTrivia(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/trivia/questions/${action.payload.questionId}/`,
      { status: action.payload.status }
    )
    if (data.success) {
      yield put(
        slice.actions.setViewTriviaQuestionStatus({
          index: action.payload.questionIndex,
          status: action.payload.status
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getEditTriviaContestDetails() {
  try {
    yield put(appActions.setIsLoading(true))
    const triviaId = yield select((store) => store.learningDevelopment.trivia.editTrivia.triviaId)
    const { data } = yield call(axios.get, `${baseUrl}/trivia/${triviaId}/`)
    if (data.success) {
      yield put(slice.actions.setEditTriviaData(data.data))
    }
    const { data: newData } = yield call(axios.get, `${baseUrl}/trivia/${triviaId}/questions/`)
    if (newData.success) {
      yield put(slice.actions.setEditTriviaQuestions(newData.data?.question ?? []))
      yield put(slice.actions.setTriviaQuestionsCount(newData.data?.category_count))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getEditTriviaQuestions() {
  try {
    yield put(appActions.setIsLoading(true))
    const triviaId = yield select((store) => store.learningDevelopment.trivia.editTrivia.triviaId)
    const { data } = yield call(axios.get, `${baseUrl}/trivia/${triviaId}/questions/`)
    if (data.success) {
      yield put(slice.actions.setEditTriviaQuestions(data.data?.question ?? []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* setQuestionStatusEditTrivia(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/trivia/questions/${action.payload.questionId}/`,
      { status: action.payload.status }
    )
    if (data.success) {
      yield put(
        slice.actions.setEditTriviaQuestionStatus({
          index: action.payload.questionIndex,
          status: action.payload.status
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* editTriviaContest(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const triviaId = yield select((store) => store.learningDevelopment.trivia.editTrivia.triviaId)
    const { data } = yield call(axios.patch, `${baseUrl}/trivia/${triviaId}/`, action.payload)
    if (data.success) {
      yield put(slice.actions.setIsContestEdited(true))
      yield put(slice.actions.setIncorrectTriviaDuration({}))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* editTriviaDeleteQuestion(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { triviaId } = yield select((store) => store.learningDevelopment.trivia.editTrivia)
    const { data } = yield call(axios.delete, `${baseUrl}/trivia/${triviaId}/questions/`, {
      data: { questions: [action.payload] }
    })
    if (data.success) {
      const { data: questions } = yield call(axios.get, `${baseUrl}/trivia/${triviaId}/questions/`)
      if (questions.success) {
        yield put(slice.actions.setEditTriviaQuestions(questions.data?.question ?? []))
      }
      toast.success("Question Deleted", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getLeaderBoardMerchant() {
  try {
    const { data } = yield call(axios.get, `${baseUrl}/frontline/merchandize-prize/`)
    if (data.success) {
      yield put(slice.actions.setLeaderBoardMerchantPrize(data.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getResturantListByTrivia(action) {
  const { date, employeeClass, restaurant } = action.payload
  let params = `month=${date}`
  if (employeeClass && employeeClass !== "All") {
    params += `&job_class=${employeeClass}`
  }
  if (restaurant && restaurant !== "All") {
    params += `&restaurant=${restaurant}`
  }

  try {
    yield put(appActions.setIsLoading(true))
    const triviaId = yield select(
      (store) => store.learningDevelopment.trivia.contestAnalytics.triviaId
    )
    const { data } = yield call(
      axios.get,
      `${baseUrl}/trivia/trivia-analytics/by-restaurant/${triviaId}/?${params}`
    )
    if (data.success) {
      yield put(slice.actions.setResturantListByTrivia(data.data ?? []))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getCorrectAnswersByTrivia(action) {
  const { date, employeeClass, restaurant } = action.payload
  let params = `month=${date}`
  if (employeeClass && employeeClass !== "All") {
    params += `&job_class=${employeeClass}`
  }
  if (restaurant && restaurant !== "All") {
    params += `&restaurant=${restaurant}`
  }

  try {
    yield put(appActions.setIsLoading(true))
    const triviaId = yield select(
      (store) => store.learningDevelopment.trivia.contestAnalytics.triviaId
    )
    const { data } = yield call(
      axios.get,
      `${baseUrl}/trivia/trivia-analytics/correct-answers/${triviaId}/?${params}`
    )
    if (data.success) {
      yield put(slice.actions.setCorrectAnswersByTrivia(data.data ?? []))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getWinnerListMerchant(action) {
  try {
    const { date } = action.payload
    let params = `date=${date}`
    const { data } = yield call(axios.get, `${baseUrl}/frontline/swag-winners/?${params}`)
    if (data.success) {
      yield put(slice.actions.setLeaderBoardWinnerList(data.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getIncorrectAnswersByTrivia(action) {
  const { date, employeeClass, restaurant } = action.payload
  let params = `month=${date}`
  if (employeeClass && employeeClass !== "All") {
    params += `&job_class=${employeeClass}`
  }
  if (restaurant && restaurant !== "All") {
    params += `&restaurant=${restaurant}`
  }

  try {
    yield put(appActions.setIsLoading(true))
    const triviaId = yield select(
      (store) => store.learningDevelopment.trivia.contestAnalytics.triviaId
    )
    const { data } = yield call(
      axios.get,
      `${baseUrl}/trivia/trivia-analytics/incorrect-answers/${triviaId}/?${params}`
    )
    if (data.success) {
      yield put(slice.actions.setIncorrectAnswersByTrivia(data.data ?? []))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getLeaderBoardUSerList(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { date, employeeClass, page, restaurant } = action.payload
    let params = `month=${date}&page=${page}`
    if (employeeClass && employeeClass !== "All") {
      params += `&job_class=${employeeClass}`
    }
    if (restaurant && restaurant !== "All") {
      params += `&restaurant=${restaurant}`
    }
    const { data } = yield call(axios.get, `${baseUrl}/trivia/trivia-leaderboard/?${params}`)
    if (data.success) {
      yield put(
        slice.actions.setLeaderBoardUserList({
          totalPages: data?.data?.pages,
          data: data?.data?.results || []
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getTopSuggestorsByTrivia(action) {
  const { date, employeeClass, restaurant } = action.payload
  let params = `month=${date}`
  if (employeeClass && employeeClass !== "All") {
    params += `&job_class=${employeeClass}`
  }
  if (restaurant && restaurant !== "All") {
    params += `&restaurant=${restaurant}`
  }

  try {
    yield put(appActions.setIsLoading(true))
    const triviaId = yield select(
      (store) => store.learningDevelopment.trivia.contestAnalytics.triviaId
    )
    const { data } = yield call(
      axios.get,
      `${baseUrl}/trivia/top-suggested-questions/${triviaId}/?${params}`
    )
    if (data.success) {
      yield put(slice.actions.setTopSuggestorsByTrivia(data.data ?? []))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getTopTriviaAnalytics() {
  try {
    const { data } = yield call(axios.get, `${baseUrl}/trivia/top-trivia-analytics/`)
    if (data.success) {
      yield put(slice.actions.setTopTrivia(data.data ?? []))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getCalendarData(action) {
  const { date, restaurant } = action.payload
  let params = `date=${date}`
  if (restaurant && restaurant !== "All") {
    params += `&restaurant=${restaurant}`
  }
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/trivia/calendar-api/?${params}`)
    if (data.success) {
      yield put(slice.actions.setCalendarData(data.data || []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getRestaurantsList() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/restaurants/`)
    if (data.success) {
      yield put(appActions.setRestaurantList(data.data || []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getTop5RanksTrivia() {
  const date = yield select((store) => store?.app?.filters?.date)

  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/trivia/trivia-top-leaderboard?month=${date}`)
    if (data.success) {
      yield put(slice.actions.setTop5RanksTrivia(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteMultipleTriviaContest(action) {
  try {
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/trivia/bulk-delete`, {
      data: {
        trivia_list: action.payload
      }
    })
    const { data } = yield call(axios.get, `${baseUrl}/trivia/pending-questions/`, action.payload)
    if (data.success) {
      let pendingQuestions = []
      data?.data?.forEach((contest) => {
        contest?.questions.forEach((question) => {
          pendingQuestions.push({ ...question, trivia: contest?.trivia })
        })
      })
      yield put(slice.actions.setPendingQuestions(pendingQuestions))
    }

    const { data: newTrivia } = yield call(axios.get, `${baseUrl}/trivia/`)
    if (newTrivia.success) {
      yield put(
        slice.actions.setTriviaList({
          totalPages: newTrivia?.data?.pages,
          data: newTrivia.data.results
        })
      )
    }
    const { data: incorrectTrivia } = yield call(
      axios.get,
      `${baseUrl}/trivia/?trivia_type=INCORRECT`
    )
    if (incorrectTrivia.success) {
      yield put(
        slice.actions.setIncorrectTriviaList({
          totalPages: incorrectTrivia?.data?.pages,
          data: incorrectTrivia.data.results
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* uploadQuestionBulk(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const headerParams = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }

    const { data } = yield call(
      axios.post,
      `${baseUrl}/trivia/trivia-question-upload/`,
      {
        file: action.payload.data
      },
      headerParams
    )

    if (data.success) {
      toast.success("Upload Successful", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })

      yield put(slice.actions.setUploadQuestionsData(data?.data?.questions || []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getMenuDinnerLunch(action) {
  try {
    const search = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/web-menu-list/${search !== "" ? `?search=${search}` : ""}`
    )
    if (data.success) {
      yield put(slice.actions.setDinnerLunchData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getMenuDinnerLunchSearch(action) {
  try {
    const { debouncedSearchTerm } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/edible-items/${
        debouncedSearchTerm !== "" ? `?search=${debouncedSearchTerm}` : ""
      }`
    )
    if (data.success) {
      yield put(slice.actions.setDinnerLunchData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getWineMenuItem(action) {
  try {
    const search = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/web-menu-list/?wine-by-glass=True${
        search !== "" ? `&search=${search}` : ""
      }`
    )
    if (data.success) {
      yield put(slice.actions.setWineMenuData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getBarMenuItem(action) {
  try {
    const search = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/web-menu-list/?bar-menu=True${search !== "" ? `&search=${search}` : ""}`
    )
    if (data.success) {
      yield put(slice.actions.setBarMenuData(data.data))
      yield put(appActions.setIsLoading(false))
    }
  } catch (e) {
    console.log(e)
    yield put(appActions.setIsLoading(false))
  }
}

export function* generateQuestion(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/trivia/generate-question/`, action.payload)
    if (data.success) {
      yield put(slice.actions.setGeneratedQuestions(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getQuestionsBank(action) {
  try {
    const question_type = action?.payload?.question_used
    const { month } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/trivia/question-bank/?page=${action.payload?.page}&page_size=9999${
        action?.payload?.platform !== "ALL" && action?.payload?.platform
          ? `&platform=${action?.payload?.platform}`
          : ""
      }${
        question_type === "INCORRECT"
          ? `&type=${question_type}${month ? `&month=${month}` : ""}`
          : question_type !== "ALL" && question_type
          ? `&question_used=${question_type}`
          : ""
      }`
    )
    if (data.success) {
      yield put(slice.actions.setQuestionBankData(data?.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addToQuestionBank(action) {
  try {
    const { payload, isAutoSuggest } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.post,
      `${baseUrl}/trivia/transfer-question/?is_auto_suggested=${isAutoSuggest}`,
      payload
    )
    if (data.success) {
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/trivia/question-bank/?page=1&page_size=9999`
      )
      if (newData.success) {
        if (!data.data.message) {
          if (!data.data.message) {
            toast.success(data?.data?.message ?? "Added to question bank", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "dark"
            })
          }
        }
        yield put(slice.actions.setQuestionBankData(newData?.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addManualQuestionToBank(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/trivia/questions/`, action.payload)
    if (data.success) {
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/trivia/question-bank/?page=1&page_size=9999`
      )
      if (newData.success) {
        yield put(slice.actions.setQuestionBankData(newData?.data))
        const { data: categoryData } = yield call(axios.get, `${baseUrl}/trivia/categories-list`)
        if (categoryData.success) {
          yield put(slice.actions.setCategoryCount(categoryData?.data))
        }
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getTriviaMonthList(action) {
  try {
    const INCORRECT = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/trivia/trivia-month-list/${INCORRECT ? "?trivia_type=INCORRECT" : ""}`
    )
    if (data.success) {
      yield put(slice.actions.setTriviaMonthList(data?.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addQuestionToTrivia(action) {
  try {
    const { payload, handleRoute } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/trivia/trivia-question-map/`, payload)
    if (data.success) {
      if (data.code === 207) {
        toast.warning(data?.data?.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark"
        })
      } else {
        handleRoute()
      }
      yield put(slice.actions.setTriviaMonthList(data?.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getAutoSuggestQuestions(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/trivia/auto-suggested-questions/?page=${action.payload?.page}&page_size=9999${
        action?.payload?.menu !== "ALL" && action?.payload?.menu
          ? `&menu=${action?.payload?.menu}`
          : ""
      }`
    )
    if (data.success) {
      yield put(slice.actions.setDMVAutoSuggestQuestions(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteQuestionFromBank(action) {
  try {
    const { question } = action.payload
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/trivia/questions/${question?.id + `/` ?? ""}`)
    const { data: newData } = yield call(
      axios.get,
      `${baseUrl}/trivia/question-bank/?page=1&page_size=9999${
        action?.payload?.platform !== "ALL" && action?.payload?.platform
          ? `&platform=${action?.payload?.platform}`
          : ""
      }${
        action.payload?.type
          ? `&type=INCORRECT`
          : action.payload?.question_used
          ? `&question_used=${action.payload?.question_used}`
          : ""
      }`
    )
    if (newData.success) {
      yield put(slice.actions.setQuestionBankData(newData?.data))
      const { data } = yield call(axios.get, `${baseUrl}/trivia/categories-list`)
      if (data.success) {
        yield put(slice.actions.setCategoryCount(data?.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getGenerateQuestionsSpirits(action) {
  try {
    const { search, page } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/spirit-menu/?platform=marketing&page=${page}&page_size=5${
        search !== "" ? `&search=${search}` : ""
      }`
    )
    if (data.success) {
      yield put(slice.actions.setSpiritsData(data?.data || {}))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getAllQuestionsByTrivia(action) {
  const { page, date, employeeClass, restaurant, orderBy } = action.payload
  const triviaId = yield select(
    (store) => store.learningDevelopment.trivia.contestAnalytics.triviaId
  )
  let params = `trivia_id=${triviaId}&page=${page}&month=${date}`
  if (employeeClass && employeeClass !== "All") {
    params += `&job_class=${employeeClass}`
  }
  if (restaurant && restaurant !== "All") {
    params += `&restaurant=${restaurant}`
  }
  if (orderBy) {
    params += `&sort_by=${orderBy}`
  }

  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/trivia/all-analytics/?${params}`)
    if (data.success) {
      yield put(
        slice.actions.setAllQuestionsByTrivia({
          pages: data.data?.pages,
          data: data?.data?.results || []
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteQuestionFromAutoSuggest(action) {
  try {
    const { question, currentPage, menu, page_size } = action.payload
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/trivia/generated-question/${question?.id}`)
    const { data: newData } = yield call(
      axios.get,
      `${baseUrl}/trivia/auto-suggested-questions/?page=${currentPage}&page_size=${page_size}${
        menu !== "ALL" && menu ? `&menu=${menu}` : ""
      }`
    )
    if (newData.success) {
      yield put(slice.actions.setDMVAutoSuggestQuestions(newData.data))
      toast.success("Question deleted successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getIncorrectTriviaDuration(action) {
  try {
    const { start_date, end_date } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/trivia/trivia-date?start_date=${start_date}&end_date=${end_date}`
    )
    if (data.success) {
      yield put(slice.actions.setIncorrectTriviaDuration(data?.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getCategoryCount() {
  try {
    const { data } = yield call(axios.get, `${baseUrl}/trivia/categories-list`)
    if (data.success) {
      yield put(slice.actions.setCategoryCount(data?.data))
    }
  } catch (e) {
    console.log(e)
  }
}
