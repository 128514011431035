import React from "react"
import { Typography } from "@mui/material"
import classes from "../../pages/private/marketing/menu/menu.module.scss"

const AddMenuItemButton = ({ onAddItems, text }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      onClick={onAddItems}
      style={{ cursor: "pointer" }}>
      <div className={classes.addIcon}>+</div>
      <Typography className={classes.addButton}>{text}</Typography>
    </div>
  )
}

export default AddMenuItemButton
