import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
  Typography
} from "@mui/material"
import AppDatePicker from "components/AppDatePicker"
import ImageUploadField from "components/FileUpload/ImageUploadField"
import VideoUploadField from "components/FileUpload/VideoUploadField"
import ImageCropper from "components/ImageCropper"
import AppTextField from "components/StyledComponents/AppTextField"
import moment from "moment/moment"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  uploadTastingNotesVideoThumbnailAction,
  uploadTastingNotesWineImageAction
} from "redux/DMV/actions"
import { DMVActions } from "redux/DMV/slice/user"
import AppSelect from "components/StyledComponents/AppSelect"

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: 8,
  "& span": {
    color: theme.palette.error.main
  }
}))

const WineDetailsForm = ({ formik, formReset }) => {
  const dispatch = useDispatch()
  const [openCrop, setCrop] = useState(false)

  const [fileDetails, setFileDetails] = useState(null)
  const [imageObjectURL, setImageObjectUrl] = useState(null)

  const [uploadType, setUploadType] = useState("")
  const { alcoholType } = useSelector((state) => state?.dmv || {})

  const onImageUpload = (image, type) => {
    setUploadType("")
    if (image) {
      setUploadType(type)
      setCrop(true)
      setFileDetails(image)
      setImageObjectUrl(URL.createObjectURL(image))
    }
  }

  const handleImageDelete = (type, formik) => {
    if (type === "wineImage") {
      dispatch(DMVActions.setTastingNotesWineImage(null))
    } else {
      dispatch(DMVActions.setTastingNotesVideoThumbnail(null))
    }
    formik.setFieldValue(type, null)
  }

  function handleCroppedImage(image, type) {
    let data = new FormData()
    data.append("media", image)
    data.append("media_type", "IMAGE")
    data.append("content_type", image.type)
    if (type === "wineImage") {
      dispatch(uploadTastingNotesWineImageAction(data))
    } else {
      dispatch(uploadTastingNotesVideoThumbnailAction(data))
    }
    formik.setFieldValue(type, image)
  }

  function showCropperModal(imageObjectURL, fileDetails, type) {
    return (
      <ImageCropper
        setSelectedImage={(image) => handleCroppedImage(image, type)}
        srcImage={imageObjectURL}
        fileDetail={fileDetails}
        aspectRatio={type === "wineImage" ? 0 : 4 / 3}
        onClose={setCrop}
        openCrop={openCrop}></ImageCropper>
    )
  }

  const handleChange = (event) => {
    dispatch(DMVActions.setAlcoholType(event.target.value))
    formReset()
  }

  function getWineColor(wineType) {
    if (wineType === "white_wine") {
      return [
        {
          label: "Lemon",
          value: "lemon"
        },
        {
          label: "Gold",
          value: "gold"
        },
        {
          label: "Amber",
          value: "amber"
        }
      ]
    } else if (wineType === "red_wine") {
      return [
        {
          label: "Medium Ruby",
          value: "medium_ruby"
        },
        {
          label: "Pale Purple",
          value: "pale_purple"
        },
        {
          label: "Medium Purple",
          value: "medium_purple"
        },
        {
          label: "Medium Garnet",
          value: "medium_garnet"
        },
        {
          label: "Deep Ruby",
          value: "deep_ruby"
        }
      ]
    } else if (wineType === "rose_wine") {
      {
        return [
          {
            label: "Medium Salmon",
            value: "medium_salmon"
          },
          {
            label: "Pale Pink",
            value: "pale_pink"
          },
          {
            label: "Medium Pink",
            value: "medium_pink"
          }
        ]
      }
    } else {
      return [
        {
          label: "Pale Gold",
          value: "pale_gold"
        },
        {
          label: "Clear Gold",
          value: "clear_gold"
        },
        {
          label: "Honey",
          value: "honey"
        },
        {
          label: "Tawny",
          value: "tawny"
        },
        {
          label: "Auburn",
          value: "auburn"
        },
        {
          label: "Burnt Amber",
          value: "burnt_amber"
        }
      ]
    }
  }

  const handleChangeWineType = (e) => {
    const wineType = e.target.value
    formik.setFieldValue(e.target.name, wineType)
    if (wineType === "brown") {
      formik.setFieldValue("week_answer", "pale_gold")
    } else if (wineType === "white_wine") {
      formik.setFieldValue("week_answer", "lemon")
    } else if (wineType === "red_wine") {
      formik.setFieldValue("week_answer", "medium_ruby")
    } else if (wineType === "rose_wine") {
      formik.setFieldValue("week_answer", "medium_salmon")
    } else {
      formik.setFieldValue("week_answer", "null")
    }
  }

  return (
    <Grid container spacing={4}>
      {showCropperModal(imageObjectURL, fileDetails, uploadType)}
      <Grid item sm={12} md={6} lg={4} className="datesContainer">
        <StyledLabel variant="body1Regular">
          {"Select Type"}
          <span>*</span>
        </StyledLabel>
        <Box className="dateFieldContainer w-100 mt-2">
          <FormControl variant="filled">
            <RadioGroup
              className="d-flex flex-row px-4"
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={alcoholType}
              onChange={(e) => {
                handleChange(e)
              }}>
              <FormControlLabel
                value="wine"
                control={<Radio color="secondary" />}
                label="Wine"
                sx={{ marginRight: "48px" }}
              />
              <FormControlLabel
                value="spirit"
                control={<Radio color="secondary" />}
                label="Spirit"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Grid>
      <Grid
        item
        sm={12}
        md={6}
        lg={4}
        sx={alcoholType === "" && { opacity: "0.3" }}
        className="datesContainer">
        <StyledLabel variant="body1Regular">
          {"Date"}
          <span>*</span>
        </StyledLabel>
        <Box className="dateFieldContainer w-100 mt-2">
          <AppDatePicker
            views={["year", "month", "day"]}
            disabled={alcoholType === "" ? true : false}
            inputFormat="dd MMM yyyy"
            key={"month"}
            minDate={new Date()}
            maxDate={moment().add(10, "y").toDate()}
            value={formik.values.week}
            onChange={(val) => formik.setFieldValue("week", val)}
            error={formik.errors.week}
            touched={formik.touched.week}
          />
        </Box>
      </Grid>
      <Grid item sm={12} md={6} lg={4} sx={alcoholType === "" && { opacity: "0.3" }}>
        <AppTextField
          label="Bottle Name"
          isRequired={true}
          isUpperCase={true}
          fullWidth
          placeholder={alcoholType === "spirit" ? "Enter Spirit Name" : "Enter Wine Name"}
          value={formik.values.wine}
          onChange={formik.handleChange("wine")}
          error={formik.touched.wine && Boolean(formik.errors.wine)}
          helperText={formik.touched.wine && formik.errors.wine}
          disabled={alcoholType === "" ? true : false}
        />
      </Grid>
      <Grid item sm={12} md={6} lg={4} sx={alcoholType === "" && { opacity: "0.3" }}>
        <AppTextField
          label="Origin"
          isRequired={true}
          fullWidth
          isUpperCase={true}
          placeholder={alcoholType === "spirit" ? "Enter spirit origin" : "Enter wine origin"}
          value={formik.values.origin}
          onChange={formik.handleChange("origin")}
          error={formik.touched.origin && Boolean(formik.errors.origin)}
          helperText={formik.touched.origin && formik.errors.origin}
          disabled={alcoholType === "" ? true : false}
        />
      </Grid>
      <Grid item sm={12} md={6} lg={4} sx={alcoholType === "" && { opacity: "0.3" }}>
        <AppTextField
          label="Personality"
          isRequired={true}
          isUpperCase={true}
          fullWidth
          placeholder="Enter personality"
          value={formik.values.personality}
          onChange={formik.handleChange("personality")}
          error={formik.touched.personality && Boolean(formik.errors.personality)}
          helperText={formik.touched.personality && formik.errors.personality}
          disabled={alcoholType === "" ? true : false}
        />
      </Grid>

      <Grid item sm={12} md={6} lg={4} sx={alcoholType === "" && { opacity: "0.3" }}>
        <AppTextField
          label={alcoholType === "spirit" ? "Ingredients" : "Grape Varietals"}
          isRequired={true}
          fullWidth
          isUpperCase={true}
          placeholder={alcoholType === "spirit" ? "Enter Ingredients" : "Enter Grape Varietals"}
          value={formik.values.grapeVarietals}
          onChange={formik.handleChange("grapeVarietals")}
          error={formik.touched.grapeVarietals && Boolean(formik.errors.grapeVarietals)}
          helperText={formik.touched.grapeVarietals && formik.errors.grapeVarietals}
          disabled={alcoholType === "" ? true : false}
        />
      </Grid>
      <Grid item sm={12} md={6} lg={4} sx={alcoholType === "" && { opacity: "0.3" }}>
        <ImageUploadField
          label="Upload Bottle Image"
          isRequired={true}
          isVariant={true}
          placeholder="Upload Image"
          value={formik.values.wineImage}
          onChange={(e) => onImageUpload(e, "wineImage")}
          onDelete={() => handleImageDelete("wineImage", formik)}
          error={formik.errors.wineImage}
          touched={formik.touched.wineImage}
          disabled={alcoholType === "" ? true : false}
        />
      </Grid>
      <Grid item sm={12} md={6} lg={4} sx={alcoholType === "" && { opacity: "0.3" }}>
        <VideoUploadField
          label="Import Video"
          placeholder="Import Video"
          importOnly={true}
          isVariant={true}
          videoUrl={formik.values.videoUrl}
          onUrlUpload={formik.handleChange("videoUrl")}
          onDelete={() => formik.handleChange("videoUrl")("")}
          error={formik.errors.videoUrl}
          touched={Boolean(formik.values.videoUrl)}
          disabled={alcoholType === "" ? true : false}
        />
      </Grid>
      <Grid item sm={12} md={6} lg={4} sx={alcoholType === "" && { opacity: "0.3" }}>
        <ImageUploadField
          label="Upload Video Thumbnail"
          placeholder="Upload Video Thumbnail"
          isVariant={true}
          isRequired={formik.values.videoUrl ? true : false}
          value={formik.values.videoThumbnail}
          onChange={(e) => onImageUpload(e, "videoThumbnail")}
          onDelete={() => handleImageDelete("videoThumbnail", formik)}
          error={formik.errors.videoThumbnail}
          touched={formik.touched.videoThumbnail}
          disabled={alcoholType === "" ? true : false}
        />
      </Grid>
      <Grid item sm={12} md={6} lg={4} sx={alcoholType === "" && { opacity: "0.3" }}>
        <AppSelect
          id="wineType"
          name="wineType"
          label={alcoholType === "wine" ? "Wine Type" : "Spirit Type"}
          menuItems={alcoholType === "wine" ? filters : spiritType}
          value={formik.values.wineType}
          onChange={(e) => handleChangeWineType(e)}
          error={formik.errors.wineType}
          isRequired={true}
          touched={formik.touched.wineType}
          disabled={alcoholType === "" ? true : false}
        />
      </Grid>
      {alcoholType === "spirit" && formik.values.wineType === "transparent" ? null : (
        <Grid item sm={12} md={6} lg={4} sx={alcoholType === "" && { opacity: "0.3" }}>
          <AppSelect
            id="week_answer"
            placeholder="hello"
            name="week_answer"
            label={alcoholType === "wine" ? "Wine Color" : "Spirit Color"}
            menuItems={getWineColor(formik.values.wineType)}
            value={formik.values.week_answer}
            onChange={formik.handleChange}
            error={formik.errors.week_answer}
            isRequired={true}
            touched={formik.touched.week_answer}
            disabled={alcoholType === "" ? true : false}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default WineDetailsForm

const filters = [
  {
    label: "White Wine",
    value: "white_wine"
  },
  {
    label: "Red Wine",
    value: "red_wine"
  },
  {
    label: "Rosé Wine",
    value: "rose_wine"
  }
]

const spiritType = [
  {
    label: "Brown",
    value: "brown"
  },
  {
    label: "Clear",
    value: "transparent"
  }
]
